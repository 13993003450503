.icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  fill: #002664
}

.link-list--icon-template {
  display: none;
}

.link-list {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
  line-height: 1.25;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}

.link-list--title,
.link-list--subtitle {
  margin-left: 16px;
}

h2 + h3.link-list--subtitle {
  padding-top: 0;
}

.link-list--primary-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F4F4F7;
  margin: 0 0 1rem 0;
  padding: 1rem 16px;
  color: #333333;
  line-height: 1.5;
}

.link-list--primary-link:hover {
  background-color: rgba(0,171,230,.2);
}

.link-list--primary-link--title {
  font-weight: 600;
}

.link-list--primary-link--subtitle {
  font-size: 0.875rem;
  font-weight: normal;
  color: #4C4F55;
}

.link-list__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  list-style-type: none
}

.link-list__item {
  border-bottom: 1px solid #a0a5ae;
  width: 100%;
  line-height: 1.5em;
}

.link-list__item:first-child{
  border-top: 1px solid #a0a5ae
}

@media (min-width: 576px) {
  .link-list__item {
    width: calc(50% - 16px);
  }

  .link-list__item:nth-child(2) {
    border-top: 1px solid #a0a5ae
  }
}

.link-list__link {
  padding: 1rem;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none
}

.link-list__link:hover {
  background-color: rgba(0,171,230,.2)
}

.link-list__link:focus {
  outline: none;
  box-shadow: 0 0 0 3px #00abe6
}

.link-list__icon {
  margin-left: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  flex: none
}

.link-list--hidden {
  display: inherit;
  flex-wrap: inherit;
  justify-content: inherit;
  width: 100%;
}

.link-list--hidden .link-list__item {
  border-top: none;
}

.link-list--actions {
  width: 100%;
  text-align: right;
}

.link-list--more {
  width: auto;
  padding: .5em 1em 0em;
  margin: 0 -1em;
  cursor: pointer;
  border: none;
  background: transparent;
  display: inline-flex;
  align-items: center;
}

.link-list--toggle {
  transform: rotate(0);
  transition: transform 300ms;
}

.link-list--toggle.open {
  transform: rotate(180deg);
  transition: transform 300ms;
}

/* 
   * Typography
   */

html {
  font-size: 16px;
}

body, p {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 0;
}

section > p {
  max-width: 100%;
}

@media (min-width: 768px) {
  section > p {
    max-width: calc(11 / 12 * 100%);
  }
}

@media (min-width: 992px) {
  section > p {
    max-width: calc(9 / 12 * 100%);
  }
}

@media (min-width: 1400px) {
  section > p {
    max-width: calc(8 / 12 * 100%);
  }
}


body {
  min-width: 320px;
  font-family: Montserrat, Arial, sans-serif;
}

body {
  background: repeating-linear-gradient(white 0, crimson 1px, white 1px, white 16 px);
}

b, strong {
  font-weight: 600;
}

i, cite {
  font-style: italic;
}

b i, i b, strong i, i strong {
  font-weight: 600;
  font-style: italic;
}

figcaption {
  font-size: 0.875rem; /* 14px at 16px base */
}


h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  margin-top: 0;
  line-height: 1.2;
}

h1[role="doc-subtitle"],
h2[role="doc-subtitle"],
h3[role="doc-subtitle"],
h4[role="doc-subtitle"],
h5[role="doc-subtitle"],
h6[role="doc-subtitle"] {
  font-weight: normal;
  padding-top: 0;
}

h1 {
  font-size: 2.06rem;
  padding-top: 8px;
  margin-bottom: 0.333em;
}

h2,
h1[role="doc-subtitle"] {
  font-size: 1.75rem;
  margin-bottom: 0.357em;
}

h2 {
  padding-top: 1.36em;
}

h3,
h2[role="doc-subtitle"] {
  font-size: 1.44rem;
  margin-bottom: 0.870em;
}

h3 {
  padding-top: 1.22em;
}

h4,
h3[role="doc-subtitle"] {
  font-size: 1.19rem;
  margin-bottom: 0.947em;
}

h4 {
  padding-top: 1.58em;
}

h5, h6 {
  font-size: 1rem;
  padding-top: 0.438em;
  margin-bottom: 1.06em;
}

p {
  font-size: 1.00rem;
  padding-top: 0.438em;
  margin-bottom: 1.06em;
  max-width: 885px;
}

p.intro {
  font-size: 1.25rem;
  line-height: 1.25;
  max-width: 100%;
}

@media (min-width: 992px) {
  h1 {
    font-size: 3rem;
    padding-top: 24px;
    margin-bottom: 0.0833em;
  }

  h2,
  h1[role="doc-subtitle"] {
    font-size: 2.06rem;
    margin-bottom: 0.333em;
  }

  h2 {
    padding-top: 1.12em;
  }
}


/* @import './measures-menu/measures-menu.css'; */
/* @import './compare-menu/compare-menu.css'; */
/* @import './chart-view-toolbar/chart-view-toolbar.css'; */
@import './button-confidence/button-confidence.css';
/* @import './legend/legend.css'; */

/* @import './plotly.css'; */


/* 
    <div class="chart">
    <div class="chart--layout">
    <div class="chart--layout--left-header">one</div>
    <div class="chart--layout--right-header">two</div>
    <div class="chart--layout--left-body">three</div>
    <div class="chart--layout--right-body">four</div>
    <div class="chart--layout--right-footer">six</div>
    </div>
    </div> 
*/

.facet {
    /* border: 1px solid red; */
    
}
.map {
    height: 100%;
}
.chart--view {
    height: 100%;
}
.facet-layout {
    height: 100%;
}


.legend {
    line-height: 18px;
    color: #555;
}
.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}


.map-tile {
    position: relative;
    display: flex;   
    flex-direction: column;
    z-index: 1;
}

.map-tile--title {
    /* height: 2em; */
    margin-bottom: 4px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.map-tile--body {
    flex-grow: 1;
}


/* Override */

.chart--layout--right-footer .ui-checkboxradio-label {
    background-color: transparent;
}

/* Component Styles */

/* .chart {} */

.chart {
    --height: 80vh;
    /* --height: 20vh; */
}


.chart--layout {
    display: grid;
    grid-template-columns: 320px minmax(0, 1fr);
    grid-template-rows: 72px 1fr 72px;
    grid-gap: 1px;
    ;
    min-height: var(--height);
    background-color: #E4E4E6;
    border: 1px solid #E4E4E6;

    max-width: 100%;
    overflow: hidden;
}

.chart--layout>* {
    padding: 16px;
    background-color: white;
}

.chart--layout--left-header {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    font-size: 1.25em;
}

.chart--layout--left-header--drawer-toggle {
    position: relative;
    right: -8px;
    width: 48px;
    height: 48px;
    font-family: 'health-stats-icons' !important;
    line-height: inherit;
    text-align: center;
    cursor: pointer;
}

.chart--layout--right-header--drawer-toggle {
    margin-left: -16px !important;
    width: 72px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    background-color: white;
}

.chart--layout--left-body {
    grid-row: 2 / 4;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(var(--height) + 72px);
}

.chart--layout--right-body {
    overflow-y: scroll;
    max-height: var(--height);
    width: 100%;
    overflow-x: hidden;
}

.chart--layout--left-body::-webkit-scrollbar, .chart--layout--right-body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
}

.chart--layout--left-body::-webkit-scrollbar-track, .chart--layout--right-body::-webkit-scrollbar-track {
    background-color: transparent;
}

.chart--layout--left-body::-webkit-scrollbar-thumb, .chart--layout--right-body::-webkit-scrollbar-thumb {
    border-radius: 4px;
    /* @todo fix magic colour */
    background-color: rgba(0, 0, 0, 0.3);
}

.chart--layout--left-body::-webkit-scrollbar-corner, .chart--layout--right-body::-webkit-scrollbar-corner {
    background-color: transparent;
}

.chart--layout--left-body::-webkit-scrollbar-corner, .chart--layout--right-body::-webkit-scrollbar-corner {
    width: 16px;
}

.chart--layout--right-header, 
.chart--layout--right-footer {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #F4F4F7;
    padding: 0 16px;
    line-height: 72px;
}

.chart--layout--right-footer {
    justify-content: flex-end;
}

.chart--layout--right-header > *, 
.chart--layout--right-footer > * {
    margin: 0 8px;
}

.chart--layout--right-header > *:first-child,
.chart--layout--right-footer > *:first-child {
    margin-left: 0;
}

.chart--layout--right-header > *:last-child,
.chart--layout--right-footer > *:last-child {
    margin-right: 0;
}

/* @todo complete IE 11 compatability */
@media all and (-ms-high-contrast:none) {
    .chart--layout {
        display: -ms-grid;
        -ms-grid-columns: 300px 1fr;
        -ms-grid-rows: 72px 1fr 72px;
    }
}

.chart--layout--right-header--drawer-toggle.icon-angle-double-right1,
.chart--layout--left-header--drawer-toggle.icon-angle-double-left1 {
  font-size: 32px;
}

.chart h1 {
    font-size: 1.25rem;
}

.chart--facet-layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    grid-auto-rows: auto;
}

.indicator-chart table {
    /* border: 1px solid grey; */
    /* font-size: 16px; */
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
}

.indicator-chart th {
    text-align: left;
    font-size: 1.1em;
    font-weight: 600;
}

.indicator-chart thead tr th {
    border-bottom: 2px solid grey;
}

.indicator-chart table .right {
    text-align: right;
}
.indicator-chart table th,
.indicator-chart table td {
    padding: 4px;
}

.indicator-chart td.alt {
    background-color: #F4F4F7;
}
div#mapid { height: 50vh }

.map {
    z-index: 0;
}

.zoom-control,
.boundry-control {
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(74, 74, 74, 0.3);
    color: #4C4F55;
    font-weight: 600;
}

.zoom-control button,
.boundry-control button {
    display: block;
    width: 100%;   
    padding: 8px 16px;
    /* border-radius: 4px; */
    
    border: none;
    border-bottom: 1px solid #E6E6E6;
    /* background: -webkit-linear-gradient(top,white 0, #f5f5f5 100%); */
    background-color: white;
    color: #4C4F55;
}

.zoom-control button:first-child,
.boundry-control button:first-child {
    border-radius: 4px 4px 0 0;
}

.zoom-control button:last-child,
.boundry-control button:last-child {
    border-radius: 0 0 4px 4px;
    border-bottom: none;
}

.zoom-control button:hover,
.boundry-control button:hover {
    background: #f5f5f5;
}

.zoom-control button:active,
.boundry-control button:active,
.boundry-control button.selected {
    background:  #e5e5e5;
}

.map .map-legend {
    display: flex;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(74, 74, 74, 0.3);
    /* background-color: white; */
    background-color: #F2F2F0;
    color: #4C4F55;
    font-weight: 600;
    height: 200px;
    padding: 16px;
}

.map .map-legend--gradient,
.map .map-legend--ticks {
    display: inline-block;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.map .map-legend--gradient {
    width: 16px;
    height: 100%;
    background: linear-gradient(180deg, hsl(53.888888888888886,98.18181818181819%,56.86274509803921%), hsl(288.43373493975906,97.64705882352942%,16.666666666666664%));
    opacity: 0.4;
    border-radius: 3px;
}

.map .map-legend--ticks {
    justify-content: space-between;
    padding-left: 8px;
}

.map .map-legend--ticks span {
    /* flex */
    /* display: inline-block;
    height: 33%;
    width: 100%;
    line-height: 33%; */
}

.map .map-highlight .leaflet-popup-content-wrapper{
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(74, 74, 74, 0.3);
}

.map .map-highlight td {
    text-align: right;
}

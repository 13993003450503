/* HTML */

textarea, select, input, button {
  width: 100%;
  outline: none;
}

input[type=text], textarea {
  /* @todo magic number? */
  border-radius: 4px;
  border: 1px solid #4C4F55;
  line-height: 20px;
  padding: 12px;
}

form input[type=text] {
  /* @todo magic number? */
  height: 48px;
}

button {
  font-size: 1rem;
  line-height: 1.5;
}

button.primary,
button.secondary {
  border-radius: 4px;
  height: 48px;
  width: fit-content;
  padding: 0 24px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
}

button.primary {
  border-color: #002664;
  background-color: #002664;
  color: white;
}

button.primary:hover {
  background-color: #003182;
  border-color: #003182;
}

button.primary:disabled,
button.secondary:disabled {
  background-color: #F4F4F7;
  border-color: #F4F4F7;
  color: #A0A5AE;
}

button.secondary {
  border-color: #002664;
  background-color: white;
  color: #333;
}

button.secondary:hover {
  background-color: #002664;
  border-color: #002664;
  color: white;
}

button.primary:focus,
button.secondary:focus {
  outline: 3px solid #0085b3;
  outline-offset: 3px;
}

button.primary:active,
button.secondary:active {
  border-color: #0085b3;
  background-color: #0085b3;
  color: white;
}

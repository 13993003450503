@charset "UTF-8";
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

h1 {
  font-size: 2em;
  margin: .67em 0
}

dl dl, dl ol, dl ul, ol dl, ol ol, ol ul, ul dl, ul ol, ul ul {
  margin: 0
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

main {
  display: block
}

pre {
  font-family: monospace, monospace;
  font-size: 1em
}

abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

b, strong {
  font-weight: bolder
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em
}

small {
  font-size: 80%
}

svg:not(:root) {
  overflow: hidden
}

button, input, select {
  margin: 0
}

button {
  overflow: visible;
  text-transform: none
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button
}

fieldset {
  padding: .35em .75em .625em
}

input {
  overflow: visible
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal
}

progress {
  display: inline-block;
  vertical-align: baseline
}

select {
  text-transform: none
}

textarea {
  margin: 0;
  overflow: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

::-moz-focus-inner {
  border-style: none;
  padding: 0
}

:-moz-focusring {
  outline: 1px dotted ButtonText
}

:-moz-ui-invalid {
  box-shadow: none
}

details, dialog {
  display: block
}

dialog {
  background-color: #fff;
  border: solid;
  color: #000;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content
}

dialog:not([open]) {
  display: none
}

summary {
  display: list-item
}

template {
  display: none
}

@media print {
  .nsw-footer, .nsw-header, .nsw-infobar, .nsw-pagination {
    display: none
  }

  .nsw-accordion__content {
    display: block
  }

  *, :after, :before {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important
  }

  a, a:visited {
    text-decoration: underline
  }

  a[href]:after {
    content: " (" attr(href) ")"
  }

  abbr[title]:after {
    content: " (" attr(title) ")"
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: ""
  }

  pre {
    white-space: pre-wrap !important
  }

  blockquote, pre {
    border: 1px solid #000;
    page-break-inside: avoid
  }

  thead {
    display: table-header-group
  }

  img, tr {
    page-break-inside: avoid
  }

  h2, h3, p {
    orphans: 3;
    widows: 3
  }

  h2, h3 {
    page-break-after: avoid
  }
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important
}

.margin-top--none {
  margin-top: 0 !important
}

.margin-top--xs {
  margin-top: .5rem !important
}

.margin-top--sm {
  margin-top: .75rem !important
}

.margin-top--md {
  margin-top: 1rem !important
}

.margin-top--lg {
  margin-top: 1.5rem !important
}

.margin-top--xl {
  margin-top: 2rem !important
}

.margin-top--xxl {
  margin-top: 3rem !important
}

.margin-top--xxxl {
  margin-top: 4rem !important
}

.margin-top--xxxxl {
  margin-top: 5rem !important
}

.nsw-m-xs {
  margin: .25rem
}

.nsw-m-top-xs {
  margin-top: .25rem
}

.nsw-m-right-xs {
  margin-right: .25rem
}

.nsw-m-bottom-xs {
  margin-bottom: .25rem
}

.nsw-m-left-xs, .nsw-m-x-xs {
  margin-left: .25rem
}

.nsw-m-x-xs {
  margin-right: .25rem
}

.nsw-m-y-xs {
  margin-top: .25rem;
  margin-bottom: .25rem
}

@media (min-width: 62em) {
  .nsw-m-xs {
    margin: .5rem
  }

  .nsw-m-top-xs {
    margin-top: .5rem
  }

  .nsw-m-right-xs {
    margin-right: .5rem
  }

  .nsw-m-bottom-xs {
    margin-bottom: .5rem
  }

  .nsw-m-left-xs, .nsw-m-x-xs {
    margin-left: .5rem
  }

  .nsw-m-x-xs {
    margin-right: .5rem
  }

  .nsw-m-y-xs {
    margin-top: .5rem;
    margin-bottom: .5rem
  }
}

.nsw-m-sm {
  margin: .5rem
}

.nsw-m-top-sm {
  margin-top: .5rem
}

.nsw-m-right-sm {
  margin-right: .5rem
}

.nsw-m-bottom-sm {
  margin-bottom: .5rem
}

.nsw-m-left-sm, .nsw-m-x-sm {
  margin-left: .5rem
}

.nsw-m-x-sm {
  margin-right: .5rem
}

.nsw-m-y-sm {
  margin-top: .5rem;
  margin-bottom: .5rem
}

@media (min-width: 62em) {
  .nsw-m-sm {
    margin: 1rem
  }

  .nsw-m-top-sm {
    margin-top: 1rem
  }

  .nsw-m-right-sm {
    margin-right: 1rem
  }

  .nsw-m-bottom-sm {
    margin-bottom: 1rem
  }

  .nsw-m-left-sm, .nsw-m-x-sm {
    margin-left: 1rem
  }

  .nsw-m-x-sm {
    margin-right: 1rem
  }

  .nsw-m-y-sm {
    margin-top: 1rem;
    margin-bottom: 1rem
  }
}

.nsw-m-md {
  margin: 1rem
}

.nsw-m-top-md {
  margin-top: 1rem
}

.nsw-m-right-md {
  margin-right: 1rem
}

.nsw-m-bottom-md {
  margin-bottom: 1rem
}

.nsw-m-left-md, .nsw-m-x-md {
  margin-left: 1rem
}

.nsw-m-x-md {
  margin-right: 1rem
}

.nsw-m-y-md {
  margin-top: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 62em) {
  .nsw-m-md {
    margin: 2rem
  }

  .nsw-m-top-md {
    margin-top: 2rem
  }

  .nsw-m-right-md {
    margin-right: 2rem
  }

  .nsw-m-bottom-md {
    margin-bottom: 2rem
  }

  .nsw-m-left-md, .nsw-m-x-md {
    margin-left: 2rem
  }

  .nsw-m-x-md {
    margin-right: 2rem
  }

  .nsw-m-y-md {
    margin-top: 2rem;
    margin-bottom: 2rem
  }
}

.nsw-m-lg {
  margin: 1.5rem
}

.nsw-m-top-lg {
  margin-top: 1.5rem
}

.nsw-m-right-lg {
  margin-right: 1.5rem
}

.nsw-m-bottom-lg {
  margin-bottom: 1.5rem
}

.nsw-m-left-lg, .nsw-m-x-lg {
  margin-left: 1.5rem
}

.nsw-m-x-lg {
  margin-right: 1.5rem
}

.nsw-m-y-lg {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

@media (min-width: 62em) {
  .nsw-m-lg {
    margin: 3rem
  }

  .nsw-m-top-lg {
    margin-top: 3rem
  }

  .nsw-m-right-lg {
    margin-right: 3rem
  }

  .nsw-m-bottom-lg {
    margin-bottom: 3rem
  }

  .nsw-m-left-lg, .nsw-m-x-lg {
    margin-left: 3rem
  }

  .nsw-m-x-lg {
    margin-right: 3rem
  }

  .nsw-m-y-lg {
    margin-top: 3rem;
    margin-bottom: 3rem
  }
}

.nsw-m-xl {
  margin: 2rem
}

.nsw-m-top-xl {
  margin-top: 2rem
}

.nsw-m-right-xl {
  margin-right: 2rem
}

.nsw-m-bottom-xl {
  margin-bottom: 2rem
}

.nsw-m-left-xl, .nsw-m-x-xl {
  margin-left: 2rem
}

.nsw-m-x-xl {
  margin-right: 2rem
}

.nsw-m-y-xl {
  margin-top: 2rem;
  margin-bottom: 2rem
}

@media (min-width: 62em) {
  .nsw-m-xl {
    margin: 4rem
  }

  .nsw-m-top-xl {
    margin-top: 4rem
  }

  .nsw-m-right-xl {
    margin-right: 4rem
  }

  .nsw-m-bottom-xl {
    margin-bottom: 4rem
  }

  .nsw-m-left-xl, .nsw-m-x-xl {
    margin-left: 4rem
  }

  .nsw-m-x-xl {
    margin-right: 4rem
  }

  .nsw-m-y-xl {
    margin-top: 4rem;
    margin-bottom: 4rem
  }
}

.nsw-m-xxl {
  margin: 2.5rem
}

.nsw-m-top-xxl {
  margin-top: 2.5rem
}

.nsw-m-right-xxl {
  margin-right: 2.5rem
}

.nsw-m-bottom-xxl {
  margin-bottom: 2.5rem
}

.nsw-m-left-xxl, .nsw-m-x-xxl {
  margin-left: 2.5rem
}

.nsw-m-x-xxl {
  margin-right: 2.5rem
}

.nsw-m-y-xxl {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem
}

@media (min-width: 62em) {
  .nsw-m-xxl {
    margin: 5rem
  }

  .nsw-m-top-xxl {
    margin-top: 5rem
  }

  .nsw-m-right-xxl {
    margin-right: 5rem
  }

  .nsw-m-bottom-xxl {
    margin-bottom: 5rem
  }

  .nsw-m-left-xxl, .nsw-m-x-xxl {
    margin-left: 5rem
  }

  .nsw-m-x-xxl {
    margin-right: 5rem
  }

  .nsw-m-y-xxl {
    margin-top: 5rem;
    margin-bottom: 5rem
  }
}

.nsw-p-xs {
  padding: .25rem
}

.nsw-p-top-xs {
  padding-top: .25rem
}

.nsw-p-right-xs {
  padding-right: .25rem
}

.nsw-p-bottom-xs {
  padding-bottom: .25rem
}

.nsw-p-left-xs, .nsw-p-x-xs {
  padding-left: .25rem
}

.nsw-p-x-xs {
  padding-right: .25rem
}

.nsw-p-y-xs {
  padding-top: .25rem;
  padding-bottom: .25rem
}

@media (min-width: 62em) {
  .nsw-p-xs {
    padding: .5rem
  }

  .nsw-p-top-xs {
    padding-top: .5rem
  }

  .nsw-p-right-xs {
    padding-right: .5rem
  }

  .nsw-p-bottom-xs {
    padding-bottom: .5rem
  }

  .nsw-p-left-xs, .nsw-p-x-xs {
    padding-left: .5rem
  }

  .nsw-p-x-xs {
    padding-right: .5rem
  }

  .nsw-p-y-xs {
    padding-top: .5rem;
    padding-bottom: .5rem
  }
}

.nsw-p-sm {
  padding: .5rem
}

.nsw-p-top-sm {
  padding-top: .5rem
}

.nsw-p-right-sm {
  padding-right: .5rem
}

.nsw-p-bottom-sm {
  padding-bottom: .5rem
}

.nsw-p-left-sm, .nsw-p-x-sm {
  padding-left: .5rem
}

.nsw-p-x-sm {
  padding-right: .5rem
}

.nsw-p-y-sm {
  padding-top: .5rem;
  padding-bottom: .5rem
}

@media (min-width: 62em) {
  .nsw-p-sm {
    padding: 1rem
  }

  .nsw-p-top-sm {
    padding-top: 1rem
  }

  .nsw-p-right-sm {
    padding-right: 1rem
  }

  .nsw-p-bottom-sm {
    padding-bottom: 1rem
  }

  .nsw-p-left-sm, .nsw-p-x-sm {
    padding-left: 1rem
  }

  .nsw-p-x-sm {
    padding-right: 1rem
  }

  .nsw-p-y-sm {
    padding-top: 1rem;
    padding-bottom: 1rem
  }
}

.nsw-p-md {
  padding: 1rem
}

.nsw-p-top-md {
  padding-top: 1rem
}

.nsw-p-right-md {
  padding-right: 1rem
}

.nsw-p-bottom-md {
  padding-bottom: 1rem
}

.nsw-p-left-md, .nsw-p-x-md {
  padding-left: 1rem
}

.nsw-p-x-md {
  padding-right: 1rem
}

.nsw-p-y-md {
  padding-top: 1rem;
  padding-bottom: 1rem
}

@media (min-width: 62em) {
  .nsw-p-md {
    padding: 2rem
  }

  .nsw-p-top-md {
    padding-top: 2rem
  }

  .nsw-p-right-md {
    padding-right: 2rem
  }

  .nsw-p-bottom-md {
    padding-bottom: 2rem
  }

  .nsw-p-left-md, .nsw-p-x-md {
    padding-left: 2rem
  }

  .nsw-p-x-md {
    padding-right: 2rem
  }

  .nsw-p-y-md {
    padding-top: 2rem;
    padding-bottom: 2rem
  }
}

.nsw-p-lg {
  padding: 1.5rem
}

.nsw-p-top-lg {
  padding-top: 1.5rem
}

.nsw-p-right-lg {
  padding-right: 1.5rem
}

.nsw-p-bottom-lg {
  padding-bottom: 1.5rem
}

.nsw-p-left-lg, .nsw-p-x-lg {
  padding-left: 1.5rem
}

.nsw-p-x-lg {
  padding-right: 1.5rem
}

.nsw-p-y-lg {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

@media (min-width: 62em) {
  .nsw-p-lg {
    padding: 3rem
  }

  .nsw-p-top-lg {
    padding-top: 3rem
  }

  .nsw-p-right-lg {
    padding-right: 3rem
  }

  .nsw-p-bottom-lg {
    padding-bottom: 3rem
  }

  .nsw-p-left-lg, .nsw-p-x-lg {
    padding-left: 3rem
  }

  .nsw-p-x-lg {
    padding-right: 3rem
  }

  .nsw-p-y-lg {
    padding-top: 3rem;
    padding-bottom: 3rem
  }
}

.nsw-p-xl {
  padding: 2rem
}

.nsw-p-top-xl {
  padding-top: 2rem
}

.nsw-p-right-xl {
  padding-right: 2rem
}

.nsw-p-bottom-xl {
  padding-bottom: 2rem
}

.nsw-p-left-xl, .nsw-p-x-xl {
  padding-left: 2rem
}

.nsw-p-x-xl {
  padding-right: 2rem
}

.nsw-p-y-xl {
  padding-top: 2rem;
  padding-bottom: 2rem
}

@media (min-width: 62em) {
  .nsw-p-xl {
    padding: 4rem
  }

  .nsw-p-top-xl {
    padding-top: 4rem
  }

  .nsw-p-right-xl {
    padding-right: 4rem
  }

  .nsw-p-bottom-xl {
    padding-bottom: 4rem
  }

  .nsw-p-left-xl, .nsw-p-x-xl {
    padding-left: 4rem
  }

  .nsw-p-x-xl {
    padding-right: 4rem
  }

  .nsw-p-y-xl {
    padding-top: 4rem;
    padding-bottom: 4rem
  }
}

.nsw-p-xxl {
  padding: 2.5rem
}

.nsw-p-top-xxl {
  padding-top: 2.5rem
}

.nsw-p-right-xxl {
  padding-right: 2.5rem
}

.nsw-p-bottom-xxl {
  padding-bottom: 2.5rem
}

.nsw-p-left-xxl, .nsw-p-x-xxl {
  padding-left: 2.5rem
}

.nsw-p-x-xxl {
  padding-right: 2.5rem
}

.nsw-p-y-xxl {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

@media (min-width: 62em) {
  .nsw-p-xxl {
    padding: 5rem
  }

  .nsw-p-top-xxl {
    padding-top: 5rem
  }

  .nsw-p-right-xxl {
    padding-right: 5rem
  }

  .nsw-p-bottom-xxl {
    padding-bottom: 5rem
  }

  .nsw-p-left-xxl, .nsw-p-x-xxl {
    padding-left: 5rem
  }

  .nsw-p-x-xxl {
    padding-right: 5rem
  }

  .nsw-p-y-xxl {
    padding-top: 5rem;
    padding-bottom: 5rem
  }
}

html {
  font-family: Montserrat, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 100%;
  line-height: 1.5;
  box-sizing: border-box
}

*, :after, :before {
  box-sizing: inherit
}

body {
  margin: 0
}

.nsw-ds-example-box {
  background: #a0a5ae;
  min-height: 3.5rem
}

.nsw-ds-example-container {
  background: #f4f4f7;
  width: 100%
}

.nsw-container {
  max-width: 75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto
}

.nsw-container--flush {
  padding-left: 0;
  padding-right: 0
}

.nsw-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -.5rem;
  margin-right: -.5rem;
  align-items: stretch
}

@media (min-width: 48em) {
  .nsw-grid {
    margin-left: -1rem;
    margin-right: -1rem
  }
}

.nsw-grid > .nsw-col {
  padding: .5rem
}

@media (min-width: 48em) {
  .nsw-grid > .nsw-col {
    padding: 1rem
  }
}

.nsw-grid--no-gutters, .nsw-grid--no-gutters > .nsw-col {
  margin-left: 0;
  margin-right: 0
}

.nsw-col {
  flex: 0 0 100%;
  max-width: 100%
}

@media (min-width: 36em) {
  .nsw-col--half {
    flex: 0 0 50%;
    max-width: 50%
  }
}

@media (min-width: 48em) {
  .nsw-col--third {
    flex: 0 0 33.333%;
    max-width: 33.333%
  }

  .nsw-col--two-thirds {
    flex: 0 0 66.666%;
    max-width: 66.666%
  }

  .nsw-col--quarter, .nsw-col--three-quarters {
    flex: 0 0 50%;
    max-width: 50%
  }
}

@media (min-width: 62em) {
  .nsw-col--quarter {
    flex: 0 0 25%;
    max-width: 25%
  }

  .nsw-col--three-quarters {
    flex: 0 0 75%;
    max-width: 75%
  }
}

.nsw-layout {
  display: block
}

@media (min-width: 48em) {
  .nsw-layout--sidebar-left {
    -ms-grid-columns: minmax(14rem, 25%) 2rem 1fr;
    grid-template:"sidebar main" auto/minmax(14rem, 25%) 1fr
  }
}

@media (min-width: 48em) {
  .nsw-layout--sidebar-left, .nsw-layout--sidebar-right {
    display: -ms-grid;
    display: grid;
    grid-gap: 2rem;
    -ms-grid-rows: auto
  }

  .nsw-layout--sidebar-right {
    -ms-grid-columns: 1fr 2rem minmax(14rem, 25%);
    grid-template:"main sidebar" auto/1fr minmax(14rem, 25%)
  }
}

.nsw-layout__sidebar {
  margin-top: 2rem;
  grid-area: sidebar
}

.nsw-layout__sidebar--nav {
  display: none
}

@media (min-width: 48em) {
  .nsw-layout__sidebar--nav {
    display: block
  }
}

.nsw-layout__content {
  margin-top: 2rem;
  grid-area: main
}

@media (min-width: 48em) {
  .nsw-layout__sidebar {
    -ms-grid-row: 1;
    -ms-grid-column: 1
  }

  .nsw-layout--sidebar-right > .nsw-layout__sidebar, .nsw-layout__content {
    -ms-grid-row: 1;
    -ms-grid-column: 3
  }

  .nsw-layout--sidebar-right > .nsw-layout__content {
    -ms-grid-row: 1;
    -ms-grid-column: 1
  }
}

.nsw-icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  fill: #002664
}

.nsw-icon--error {
  fill: #b81237
}

.nsw-icon--success {
  fill: #00a908
}

.nsw-icon--info {
  fill: #2e5299
}

.nsw-icon--warning {
  fill: #dc5800
}

.nsw-icon--rotate-90 {
  transform: rotate(90deg)
}

.nsw-icon--rotate-180 {
  transform: rotate(180deg)
}

.nsw-icon--rotate-270 {
  transform: rotate(270deg)
}

.nsw-form-label {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  display: block
}

@media (min-width: 62em) {
  .nsw-form-label {
    font-size: 1rem;
    line-height: 1.5
  }
}

.nsw-form-label + input, .nsw-form-label + select, .nsw-form-label + textarea {
  margin-top: .25rem
}

:not(legend) + .nsw-form-label {
  margin-top: 1rem
}

.nsw-form-input, .nsw-form-select {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  padding: .75rem 1rem;
  border-radius: .25rem;
  background-color: #fff;
  color: #333;
  border: 1px solid #4c4f55;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%
}

@media (min-width: 62em) {
  .nsw-form-input, .nsw-form-select {
    font-size: 1rem;
    line-height: 1.5
  }
}

.nsw-form-input:hover, .nsw-form-select:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-form-input:focus, .nsw-form-select:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-form-input:disabled, .nsw-form-select:disabled {
  background-color: #f4f4f7;
  border-color: #a0a5ae;
  color: #a0a5ae;
  cursor: not-allowed
}

.nsw-form-input.has-error, .nsw-form-input[aria-invalid=true], .nsw-form-select.has-error, .nsw-form-select[aria-invalid=true] {
  border-color: #b81237;
  border-width: 2px
}

.nsw-form-input.has-error:hover, .nsw-form-input[aria-invalid=true]:hover, .nsw-form-select.has-error:hover, .nsw-form-select[aria-invalid=true]:hover {
  background-color: #f7e7eb
}

.nsw-form-select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23002664' xmlns='https://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath d='M16 26l16-16-4-4-12 12L4 6l-4 4 16 16z'/%3E%3C/svg%3E");
  padding-right: 3rem;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1rem auto
}

.nsw-form-select:disabled {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23a0a5ae' xmlns='https://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath d='M16 26l16-16-4-4-12 12L4 6l-4 4 16 16z'/%3E%3C/svg%3E")
}

.nsw-form-select option {
  background-color: #fff
}

.nsw-form-checkbox, .nsw-form-radio {
  clear: left
}

.nsw-form-checkbox__input, .nsw-form-radio__input {
  position: absolute;
  opacity: 0
}

.nsw-form-checkbox__input:hover + .nsw-form-checkbox__label:before, .nsw-form-checkbox__input:hover + .nsw-form-radio__label:before, .nsw-form-radio__input:hover + .nsw-form-checkbox__label:before, .nsw-form-radio__input:hover + .nsw-form-radio__label:before {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-form-checkbox__input:checked + .nsw-form-checkbox__label:after, .nsw-form-radio__input:checked + .nsw-form-checkbox__label:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23fff' xmlns='https://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath d='M29.362 3.742L10.495 22.946l-7.857-7.997L0 17.634l9.175 9.34 1.319 1.284 1.318-1.284L31.999 6.427z'/%3E%3C/svg%3E");
  background-color: #002664
}

.nsw-form-checkbox__input:checked + .nsw-form-radio__label:after, .nsw-form-radio__input:checked + .nsw-form-radio__label:after {
  background-color: #002664
}

.nsw-form-checkbox__input:disabled + .nsw-form-checkbox__label, .nsw-form-checkbox__input:disabled + .nsw-form-radio__label, .nsw-form-radio__input:disabled + .nsw-form-checkbox__label, .nsw-form-radio__input:disabled + .nsw-form-radio__label {
  cursor: not-allowed
}

.nsw-form-checkbox__input:disabled + .nsw-form-checkbox__label:before, .nsw-form-checkbox__input:disabled + .nsw-form-radio__label:before, .nsw-form-radio__input:disabled + .nsw-form-checkbox__label:before, .nsw-form-radio__input:disabled + .nsw-form-radio__label:before {
  border-color: #a0a5ae;
  background-color: #f4f4f7
}

.nsw-form-checkbox__input:disabled:checked + .nsw-form-checkbox__label:after, .nsw-form-checkbox__input:disabled:checked + .nsw-form-radio__label:after, .nsw-form-radio__input:disabled:checked + .nsw-form-checkbox__label:after, .nsw-form-radio__input:disabled:checked + .nsw-form-radio__label:after {
  background-color: #a0a5ae
}

.nsw-form-checkbox__input:focus + .nsw-form-checkbox__label:before, .nsw-form-radio__input:focus + .nsw-form-checkbox__label:before {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-form-checkbox__input:focus + .nsw-form-radio__label:before, .nsw-form-radio__input:focus + .nsw-form-radio__label:before {
  box-shadow: 0 0 0 3px #fff, 0 0 0 6px #0085b3
}

.nsw-form-checkbox__input.has-error + .nsw-form-checkbox__label:hover:before, .nsw-form-checkbox__input[aria-invalid=true] + .nsw-form-checkbox__label:hover:before, .nsw-form-radio__input.has-error + .nsw-form-checkbox__label:hover:before, .nsw-form-radio__input[aria-invalid=true] + .nsw-form-checkbox__label:hover:before {
  background-color: #f7e7eb
}

.nsw-form-checkbox__input.has-error + .nsw-form-checkbox__label:before, .nsw-form-checkbox__input[aria-invalid=true] + .nsw-form-checkbox__label:before, .nsw-form-radio__input.has-error + .nsw-form-checkbox__label:before, .nsw-form-radio__input[aria-invalid=true] + .nsw-form-checkbox__label:before {
  border-width: 2px;
  border-color: #b81237
}

.nsw-form-checkbox__label, .nsw-form-radio__label {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1rem;
  padding-left: 3rem;
  padding-top: .5rem;
  min-height: 2rem;
  display: block;
  position: relative
}

@media (min-width: 62em) {
  .nsw-form-checkbox__label, .nsw-form-radio__label {
    font-size: 1rem;
    line-height: 1.5
  }
}

.nsw-form-checkbox__label:before, .nsw-form-radio__label:before {
  margin-right: 1rem;
  content: "";
  width: 2rem;
  height: 2rem;
  border: 1px solid #4c4f55;
  background: #fff;
  top: 0;
  left: 0;
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
  position: absolute
}

.nsw-form-checkbox__label:after, .nsw-form-radio__label:after {
  content: "";
  position: absolute;
  width: 1.375rem;
  height: 1.375rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 1rem auto;
  left: .3125rem;
  top: 1.125rem;
  transform: translateY(-50%);
  margin-top: -.125rem
}

.nsw-form-checkbox__label:before {
  border-radius: .25rem
}

.nsw-form-radio__label:after, .nsw-form-radio__label:before {
  border-radius: 50%
}

.nsw-form-helper {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: .875rem;
  line-height: 1.35;
  display: block;
  margin-bottom: .25rem
}

@media (min-width: 62em) {
  .nsw-form-helper {
    font-size: .875rem;
    line-height: 1.35
  }
}

.nsw-form-helper--error {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23b81237' xmlns='https://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath d='M16 32A16.003 16.003 0 0027.314 4.686a16.003 16.003 0 00-22.628 0 16.003 16.003 0 000 22.628C7.687 30.314 11.756 32 16 32zm0-25a2 2 0 110 4.001A2 2 0 0116 7zm-2 7h4v11h-4V14z'/%3E%3C/svg%3E");
  padding: .5rem 1rem .5rem 2rem;
  margin-top: .5rem;
  background-repeat: no-repeat;
  background-position: left .5rem center;
  background-size: 1rem auto;
  background-color: #f7e7eb
}

.nsw-form-fieldset {
  margin: 0;
  padding: 1rem 0;
  border: 0;
  position: relative
}

.nsw-form-fieldset legend {
  padding: 0 0 1rem;
  width: 100%;
  display: block;
  float: left
}

.nsw-form-fieldset:after {
  border-radius: .25rem;
  z-index: -100;
  content: none;
  width: calc(100% + 18px);
  height: 100%;
  border: 2px solid #b81237;
  position: absolute;
  top: 0;
  left: -9px
}

.nsw-form-fieldset.has-error:after, .nsw-form-fieldset[aria-invalid=true]:after {
  content: ""
}

@media (min-width: 48em) {
  .nsw-form-fieldset.has-error, .nsw-form-fieldset[aria-invalid=true] {
    padding: 1rem
  }

  .nsw-form-fieldset.has-error:after, .nsw-form-fieldset[aria-invalid=true]:after {
    content: ""
  }

  .nsw-form-fieldset:after {
    width: 100%;
    left: 0
  }
}

.nsw-form-legend-text {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.25;
  width: 100%;
  display: block
}

@media (min-width: 62em) {
  .nsw-form-legend-text {
    font-size: 1.5rem;
    line-height: 1.25
  }
}

* + .nsw-form-group {
  margin-top: 3rem;
  border: 0
}

.nsw-form-search {
  display: flex;
  position: relative
}

.nsw-form-search__input {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  padding: .75rem 1rem;
  border-radius: .25rem;
  background-color: #fff;
  color: #333;
  border: 1px solid #4c4f55;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%
}

@media (min-width: 62em) {
  .nsw-form-search__input {
    font-size: 1rem;
    line-height: 1.5
  }
}

.nsw-form-search__input:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  z-index: 100
}

.nsw-form-search__input:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-form-search__submit {
  border-radius: .25rem;
  padding: 0 .75rem;
  border: 1px solid #4c4f55;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center
}

.nsw-form-search__submit:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0
}

.nsw-form-search__submit:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-table {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
  caption-side: bottom
}

@media (min-width: 62em) {
  .nsw-table {
    font-size: 1rem;
    line-height: 1.5
  }
}

.nsw-table caption, .nsw-table td, .nsw-table th {
  padding: .75rem .5rem;
  text-align: left
}

.nsw-table td, .nsw-table th {
  vertical-align: baseline;
  border-bottom: 1px solid #333
}

.nsw-table tfoot td, .nsw-table th, .nsw-table thead td {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600
}

.nsw-table thead {
  border-bottom: 2px solid #333
}

.nsw-table--caption-top {
  caption-side: top
}

.nsw-table--striped td, .nsw-table--striped th {
  border-bottom: 0
}

.nsw-table--striped tbody tr:nth-of-type(2n) {
  background-color: #f4f4f7
}

.nsw-table--bordered td, .nsw-table--bordered th {
  border: 1px solid #333
}

.nsw-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}

.nsw-table-responsive:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-body-content, .nsw-wysiwyg-content {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #333
}

@media (min-width: 62em) {
  .nsw-body-content, .nsw-wysiwyg-content {
    font-size: 1rem;
    line-height: 1.5
  }
}

.nsw-body-content a:not(.nsw-button):not(.nsw-tag), .nsw-wysiwyg-content a:not(.nsw-button):not(.nsw-tag) {
  color: #002664;
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto
}

.nsw-body-content a:not(.nsw-button):not(.nsw-tag):hover, .nsw-wysiwyg-content a:not(.nsw-button):not(.nsw-tag):hover {
  background-color: rgba(0, 133, 179, .2);
  outline: 2px solid rgba(0, 133, 179, .2)
}

.nsw-body-content a:not(.nsw-button):not(.nsw-tag):focus, .nsw-wysiwyg-content a:not(.nsw-button):not(.nsw-tag):focus {
  outline: 3px solid #0085b3;
  outline-offset: 0
}

.nsw-body-content a:not(.nsw-button):not(.nsw-tag):visited, .nsw-wysiwyg-content a:not(.nsw-button):not(.nsw-tag):visited {
  color: #551a8b
}

.nsw-body-content a:not(.nsw-button):not(.nsw-tag):active, .nsw-wysiwyg-content a:not(.nsw-button):not(.nsw-tag):active {
  background-color: #0085b3;
  color: #fff
}

.nsw-body-content dl, .nsw-body-content ol, .nsw-body-content p, .nsw-body-content ul, .nsw-wysiwyg-content dl, .nsw-wysiwyg-content ol, .nsw-wysiwyg-content p, .nsw-wysiwyg-content ul {
  margin: 0
}

.nsw-body-content * + dl, .nsw-body-content * + ol, .nsw-body-content * + p, .nsw-body-content * + ul, .nsw-wysiwyg-content * + dl, .nsw-wysiwyg-content * + ol, .nsw-wysiwyg-content * + p, .nsw-wysiwyg-content * + ul {
  margin-top: 1rem
}

.nsw-body-content dt, .nsw-body-content ol li, .nsw-body-content ul li, .nsw-wysiwyg-content dt, .nsw-wysiwyg-content ol li, .nsw-wysiwyg-content ul li {
  margin-top: .5rem
}

.nsw-body-content small, .nsw-wysiwyg-content small {
  font-size: .875rem;
  line-height: 1.35
}

@media (min-width: 62em) {
  .nsw-body-content small, .nsw-wysiwyg-content small {
    font-size: .875rem;
    line-height: 1.35
  }
}

.nsw-body-content abbr, .nsw-body-content acronym, .nsw-wysiwyg-content abbr, .nsw-wysiwyg-content acronym {
  text-decoration: none;
  border-bottom: 1px dashed #002664
}

.nsw-body-content blockquote, .nsw-wysiwyg-content blockquote {
  font-size: 1.375rem;
  line-height: 1.25;
  margin-top: 1rem;
  border-left: 6px solid #47888E;
  padding: 1.25rem 1.5rem
}

@media (min-width: 62em) {
  .nsw-body-content blockquote, .nsw-wysiwyg-content blockquote {
    font-size: 1.5rem;
    line-height: 1.25
  }
}

@media (min-width: 48em) {
  .nsw-body-content blockquote, .nsw-wysiwyg-content blockquote {
    margin-left: auto;
    margin-right: auto;
    max-width: 75%
  }
}

.nsw-body-content cite, .nsw-wysiwyg-content cite {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1rem;
  display: block;
  font-style: normal
}

@media (min-width: 62em) {
  .nsw-body-content cite, .nsw-wysiwyg-content cite {
    font-size: 1rem;
    line-height: 1.5
  }
}

.nsw-body-content cite:before, .nsw-wysiwyg-content cite:before {
  content: "— "
}

.nsw-wysiwyg-content h1, .nsw-wysiwyg-content h2, .nsw-wysiwyg-content h3, .nsw-wysiwyg-content h4, .nsw-wysiwyg-content h5, .nsw-wysiwyg-content h6 {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  margin: 0
}

.nsw-wysiwyg-content h1 {
  font-size: 2.25rem;
  line-height: 1.25
}

@media (min-width: 62em) {
  .nsw-wysiwyg-content h1 {
    font-size: 3rem;
    line-height: 1.25
  }
}

.nsw-wysiwyg-content h2 {
  font-size: 1.75rem;
  line-height: 1.25
}

@media (min-width: 62em) {
  .nsw-wysiwyg-content h2 {
    font-size: 2rem;
    line-height: 1.25
  }
}

.nsw-wysiwyg-content h3 {
  font-size: 1.375rem;
  line-height: 1.25
}

@media (min-width: 62em) {
  .nsw-wysiwyg-content h3 {
    font-size: 1.5rem;
    line-height: 1.25
  }
}

.nsw-wysiwyg-content h4 {
  font-size: 1.125rem;
  line-height: 1.33
}

@media (min-width: 62em) {
  .nsw-wysiwyg-content h4 {
    font-size: 1.25rem;
    line-height: 1.33
  }
}

.nsw-wysiwyg-content h5, .nsw-wysiwyg-content h6 {
  font-size: 1rem;
  line-height: 1.5
}

@media (min-width: 62em) {
  .nsw-wysiwyg-content h5, .nsw-wysiwyg-content h6 {
    font-size: 1rem;
    line-height: 1.5
  }
}

.nsw-wysiwyg-content .nsw-intro {
  font-size: 1.125rem;
  line-height: 1.33
}

@media (min-width: 62em) {
  .nsw-wysiwyg-content .nsw-intro {
    font-size: 1.25rem;
    line-height: 1.33
  }
}

.nsw-wysiwyg-content * + h1 {
  margin-top: 4rem
}

.nsw-wysiwyg-content * + h2 {
  margin-top: 3rem
}

.nsw-wysiwyg-content * + h3 {
  margin-top: 2rem
}

.nsw-wysiwyg-content * + h4, .nsw-wysiwyg-content * + h5, .nsw-wysiwyg-content * + h6 {
  margin-top: 1.5rem
}

.nsw-body-content * + p {
  margin-top: 1rem
}

.nsw-text-link {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  color: #002664;
  display: inline-block
}

.nsw-text-link:hover {
  background-color: rgba(0, 133, 179, .2);
  outline: 2px solid rgba(0, 133, 179, .2)
}

.nsw-text-link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0
}

.nsw-text-link:active {
  background-color: #0085b3;
  color: #fff
}

.nsw-accordion {
  margin: 1rem 0 0
}

.nsw-accordion__button, .nsw-accordion__title {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  margin: .5rem 0 0;
  padding: 1rem 3rem 1rem 1rem;
  border-left: 4px solid #002664;
  background-color: #f4f4f7;
  display: block;
  width: 100%;
  text-align: left;
  position: relative
}

@media (min-width: 62em) {
  .nsw-accordion__button, .nsw-accordion__title {
    font-size: 1rem;
    line-height: 1.5
  }
}

.is-ready .nsw-accordion__title {
  border-left: 0;
  padding: 0;
  margin-top: 0
}

.nsw-accordion__button {
  border: 0;
  border-left: 4px solid #002664
}

.nsw-accordion__button:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-accordion__button:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px;
  z-index: 100;
  position: relative
}

.nsw-accordion__icon {
  transform: translateY(-50%) rotate(90deg);
  height: 1rem;
  width: 1rem;
  position: absolute;
  top: 50%;
  right: 1rem
}

.is-open .nsw-accordion__icon {
  transform: translateY(-50%) rotate(270deg)
}

.nsw-accordion__content {
  padding: 1rem;
  border-left: 4px solid #002664
}

.nsw-breadcrumb {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: .875rem;
  line-height: 1.35;
  margin-top: 1.5rem
}

@media (min-width: 62em) {
  .nsw-breadcrumb {
    font-size: .875rem;
    line-height: 1.35
  }
}

.nsw-breadcrumb__list {
  margin: 0;
  padding-left: 0;
  list-style: none
}

.nsw-breadcrumb__item {
  display: none
}

.nsw-breadcrumb__item + .nsw-breadcrumb__item:before {
  content: "";
  display: inline-block;
  border-right: 2px solid;
  border-bottom: 2px solid;
  width: .375rem;
  height: .375rem;
  transform: rotate(-45deg);
  margin-right: .375rem;
  margin-left: .375rem;
  top: -1px;
  position: relative
}

@media (min-width: 48em) {
  .nsw-breadcrumb__item {
    display: inline
  }
}

.nsw-breadcrumb__item:last-child, .nsw-breadcrumb__item:nth-last-child(2) {
  display: inline
}

.nsw-breadcrumb__item:nth-last-child(2):before {
  display: none
}

@media (min-width: 48em) {
  .nsw-breadcrumb__item:nth-last-child(2):before {
    display: inline-block
  }
}

.nsw-breadcrumb__link {
  color: #002664;
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto
}

.nsw-breadcrumb__link:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-breadcrumb__link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0
}

.nsw-breadcrumb__link:active {
  background-color: #0085b3;
  color: #fff
}

.nsw-breadcrumb--current {
  text-decoration: none;
  pointer-events: none;
  color: #333
}

.nsw-button {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  margin: 1.5rem 1rem 0 0;
  border-radius: .25rem;
  border: 2px solid transparent;
  white-space: normal;
  padding: .75rem 1.5rem;
  cursor: pointer;
  min-width: 7.75rem;
  -webkit-appearance: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block
}

@media (min-width: 62em) {
  .nsw-button {
    font-size: 1rem;
    line-height: 1.5
  }
}

.nsw-button:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-button--primary {
  color: #fff;
  background-color: #002664;
  border-color: #002664
}

.nsw-button--primary:hover {
  color: #fff;
  background-color: #003182;
  border-color: #003182
}

.nsw-button--highlight {
  color: #fff;
  background-color: #d7153a;
  border-color: #d7153a
}

.nsw-button--highlight:hover {
  color: #fff;
  background-color: #e92147;
  border-color: #e92147
}

.nsw-button--outline {
  color: #333;
  background-color: #fff;
  border-color: #002664
}

.nsw-button--outline:hover {
  color: #fff;
  background-color: #002664;
  border-color: #002664
}

.nsw-button--white {
  color: #333;
  background-color: #fff;
  border-color: #fff
}

.nsw-button--white:hover {
  color: #333;
  background-color: #e4e4e6;
  border-color: #e4e4e6
}

.nsw-button:active {
  background-color: #0085b3;
  color: #fff;
  border-color: #0085b3
}

.nsw-button.disabled, .nsw-button:disabled {
  background-color: #f4f4f7;
  color: #a0a5ae;
  border-color: #f4f4f7;
  cursor: not-allowed
}

.nsw-button--block {
  display: block;
  width: 100%
}

.nsw-button--block + .nsw-button--block {
  margin-bottom: 0
}

.nsw-callout {
  padding: 1rem;
  margin-top: 1rem;
  background: #f4f4f7;
  position: relative;
  border-left: 6px solid #002664
}

@media (min-width: 48em) {
  .nsw-callout {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap
  }
}

.nsw-callout__icon {
  width: 1.5rem;
  height: 1.5rem
}

.nsw-callout__icon + .nsw-callout__content {
  margin-top: 1rem
}

@media (min-width: 48em) {
  .nsw-callout__icon {
    margin-right: 1rem;
    flex: 0 0 auto;
    width: 2rem;
    height: 2rem
  }

  .nsw-callout__icon + .nsw-callout__content {
    margin-top: 0
  }
}

.nsw-callout__content {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400
}

@media (min-width: 48em) {
  .nsw-callout__content {
    margin-top: 0
  }
}

.nsw-callout__content p {
  margin: .75rem 0 0
}

.nsw-callout__content .nsw-text-link {
  margin-top: .75rem
}

.nsw-callout__title {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.33;
  margin: 0
}

@media (min-width: 62em) {
  .nsw-callout__title {
    font-size: 1.25rem;
    line-height: 1.33
  }
}

.nsw-card {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  border-radius: .25rem;
  background-color: #fff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .15);
  transition: box-shadow .25s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-width: 25rem;
  border: 0
}

.nsw-card:hover {
  box-shadow: 2px 6px 12px 0 rgba(0, 0, 0, .25)
}

.nsw-card:focus-within {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-card:focus-within .nsw-card__link {
  text-decoration: none
}

.nsw-card__title {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.33;
  margin: 0
}

@media (min-width: 62em) {
  .nsw-card__title {
    font-size: 1.25rem;
    line-height: 1.33
  }
}

.nsw-card__content {
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem 1rem 5rem;
  border-top: 6px solid #d7153a;
  display: flex;
  flex-direction: column;
  order: 2
}

@media (min-width: 62em) {
  .nsw-card__content {
    font-size: 1rem;
    line-height: 1.5;
    padding: 2rem 2rem 5rem
  }
}

.nsw-card__copy {
  margin: 1rem 0 0
}

.nsw-card__tag {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  order: 1;
  margin: 0 0 1rem
}

.nsw-card__tag + .nsw-card__date {
  margin-top: -.5rem
}

.nsw-card__date {
  order: 2;
  margin: 0 0 1rem
}

.nsw-card__date, .nsw-card__tag {
  font-size: .875rem;
  line-height: 1.35
}

@media (min-width: 62em) {
  .nsw-card__date, .nsw-card__tag {
    font-size: .875rem;
    line-height: 1.35
  }
}

.nsw-card__copy, .nsw-card__icon, .nsw-card__title {
  order: 3
}

.nsw-card__icon {
  margin: 1rem 0 0;
  bottom: 1rem;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem
}

@media (min-width: 62em) {
  .nsw-card__icon {
    bottom: 2rem;
    margin: 2rem 0 0
  }
}

.nsw-card__link {
  text-decoration: none;
  color: #002664
}

.nsw-card__link:after {
  z-index: 100;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.nsw-card__link:focus, .nsw-card__link:hover {
  text-decoration: underline
}

.nsw-card__link:focus {
  outline: none
}

.nsw-card__image-area {
  order: 1;
  height: 12.5rem
}

.nsw-card__image {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.nsw-card--headline .nsw-card__title {
  font-size: 1.375rem;
  line-height: 1.25
}

@media (min-width: 62em) {
  .nsw-card--headline .nsw-card__title {
    font-size: 1.5rem;
    line-height: 1.25
  }
}

.nsw-direction-link {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  position: relative;
  display: inline-flex;
  text-decoration: none;
  vertical-align: middle;
  padding: .25rem .5rem;
  color: #333
}

.nsw-direction-link:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-direction-link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0
}

.nsw-direction-link .nsw-icon {
  width: 1rem;
  height: 1rem;
  -ms-grid-row-align: center;
  align-self: center
}

.nsw-direction-link .nsw-icon + .nsw-direction-link__text {
  margin-left: .5rem
}

.nsw-direction-link__text {
  -ms-grid-row-align: center;
  align-self: center
}

.nsw-direction-link__text + .nsw-icon {
  margin-left: .5rem
}

.nsw-direction-link.is-disabled {
  color: #a0a5ae;
  cursor: not-allowed;
  background: none
}

.nsw-direction-link.is-disabled .nsw-icon {
  fill: #a0a5ae
}

.nsw-feature-tile {
  padding: 1rem 0;
  border-top: 6px solid #d7153a;
  max-width: 500px
}

.nsw-feature-tile__title {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 1.25;
  margin: 0
}

@media (min-width: 62em) {
  .nsw-feature-tile__title {
    font-size: 2rem;
    line-height: 1.25
  }
}

.nsw-feature-tile__title .nsw-icon {
  margin-right: 1rem;
  height: 2.875rem;
  width: 2.875rem;
  vertical-align: middle
}

.nsw-feature-tile__title-link {
  text-decoration: none;
  color: #333
}

.nsw-feature-tile__title-link:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-feature-tile__title-link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-feature-tile__cards-group {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1rem;
  padding: 0;
  list-style: none
}

@media (min-width: 62em) {
  .nsw-feature-tile__cards-group {
    font-size: 1rem;
    line-height: 1.5
  }
}

@media (min-width: 48em) {
  .nsw-feature-tile__cards-group {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: nowrap
  }
}

.nsw-feature-tile__card {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: nowrap
}

@media (min-width: 48em) {
  .nsw-feature-tile__card {
    display: block;
    width: 32%;
    max-width: 9.625rem
  }

  .nsw-feature-tile__card:nth-child(3n+2) {
    margin-left: 2%;
    margin-right: 2%
  }
}

.nsw-feature-tile__card-img {
  margin-right: 1rem;
  display: block;
  width: 30%;
  height: auto;
  flex: 0 0 auto
}

@media (min-width: 48em) {
  .nsw-feature-tile__card-img {
    display: block;
    width: 100%;
    height: auto;
    flex: 0 0 auto;
    margin-right: auto
  }
}

.nsw-feature-tile__card-text {
  margin: 0;
  flex: 1 1 auto;
  -ms-grid-row-align: center;
  align-self: center
}

@media (min-width: 48em) {
  .nsw-feature-tile__card-text {
    padding: .5rem;
    text-align: center;
    display: block
  }
}

.nsw-feature-tile__links-group {
  margin-top: 1rem;
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5
}

@media (min-width: 62em) {
  .nsw-feature-tile__links-group {
    font-size: 1rem;
    line-height: 1.5
  }
}

@media (min-width: 48em) {
  .nsw-feature-tile__links-group {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: nowrap
  }
}

.nsw-feature-tile__links-group ul {
  padding: 0;
  margin: 0;
  list-style: none;
  flex: 1 0 50%
}

.nsw-feature-tile__link {
  text-decoration: none;
  color: #002664
}

.nsw-feature-tile__link:hover {
  background-color: rgba(0, 133, 179, .2);
  outline: 2px solid rgba(0, 133, 179, .2)
}

.nsw-feature-tile__link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0
}

.nsw-feature-tile__link:visited {
  color: #551a8b
}

.nsw-feature-tile__link:active {
  background-color: #0085b3;
  color: #fff
}

.nsw-feature-tile .new-text {
  color: #d7153a;
  text-decoration: none
}

.nsw-feature-tile .link-text {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto
}

.nsw-feature-tile__cta {
  margin-top: 2rem;
  text-decoration: underline
}

.nsw-hero-banner {
  position: relative
}

@media (min-width: 48em) {
  .nsw-hero-banner {
    height: 23.75rem;
    overflow: hidden
  }
}

@media (min-width: 48em) {
  .nsw-hero-banner__image-area {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
  }
}

.nsw-hero-banner__image {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  height: auto;
  display: block
}

@media (min-width: 48em) {
  .nsw-hero-banner__image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-bottom: 0
  }
}

.nsw-hero-banner__content-area {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw
}

@media (min-width: 48em) {
  .nsw-hero-banner__content-area {
    width: auto;
    position: static;
    margin-left: auto;
    margin-right: auto;
    left: auto;
    right: auto;
    height: 23.75rem;
    width: 100%
  }
}

.nsw-hero-banner__content {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.33;
  padding: 1rem;
  background-color: #e4e4e6;
  border-top: 6px solid #d7153a
}

@media (min-width: 62em) {
  .nsw-hero-banner__content {
    font-size: 1.25rem;
    line-height: 1.33
  }
}

@media (min-width: 48em) {
  .nsw-hero-banner__content {
    padding: 1rem 1.5rem;
    position: relative;
    top: 50%;
    background-color: hsla(0, 0%, 100%, .8);
    transform: translateY(-50%);
    max-width: 37.5rem;
    left: -1rem
  }
}

.nsw-hero-banner__content p {
  margin: 0
}

.nsw-hero-banner__content * + p {
  margin-top: 1rem
}

.nsw-hero-banner__content:focus-within {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-hero-banner__content:focus-within .nsw-hero-banner__link {
  text-decoration: none
}

.nsw-hero-banner__content .nsw-icon {
  width: 2rem;
  height: 2rem
}

.nsw-hero-banner__title {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 1.25;
  margin: 0
}

@media (min-width: 62em) {
  .nsw-hero-banner__title {
    font-size: 2rem;
    line-height: 1.25
  }
}

.nsw-hero-banner__icon {
  margin: 1rem 0 0
}

.nsw-hero-banner__link {
  text-decoration: none;
  color: #333
}

.nsw-hero-banner__link:after {
  z-index: 100;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.nsw-hero-banner__link:hover {
  text-decoration: underline
}

.nsw-hero-banner__link:focus {
  outline: none;
  text-decoration: underline
}

.nsw-hero-banner--dark .nsw-hero-banner__content {
  color: #fff;
  background-color: #333
}

@media (min-width: 48em) {
  .nsw-hero-banner--dark .nsw-hero-banner__content {
    background-color: rgba(0, 0, 0, .8)
  }
}

.nsw-hero-banner--dark .nsw-hero-banner__link {
  color: #fff
}

.nsw-hero-banner--dark .nsw-hero-banner__icon {
  fill: #fff
}

@media (min-width: 48em) {
  .hero-search {
    padding: 2rem 0
  }
}

.hero-search__title {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 1.25;
  margin: 0
}

@media (min-width: 62em) {
  .hero-search__title {
    font-size: 3rem;
    line-height: 1.25
  }
}

.hero-search__form {
  margin: 1.5rem 0
}

.hero-search__content {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 2rem 1rem;
  border-radius: .25rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f4f4f7;
  border-bottom: 6px solid #d7153a;
  text-align: center
}

@media (min-width: 48em) {
  .hero-search__content {
    padding: 2rem 5rem;
    width: auto;
    position: static;
    margin-left: auto;
    margin-right: auto;
    left: auto;
    right: auto
  }
}

.hero-search__content > * {
  max-width: 43.75rem;
  margin-left: auto;
  margin-right: auto
}

.hero-search__content p {
  font-size: 1.125rem;
  line-height: 1.33;
  margin-left: auto;
  margin-right: auto
}

@media (min-width: 62em) {
  .hero-search__content p {
    font-size: 1.25rem;
    line-height: 1.33
  }
}

.hero-search__suggested ul {
  padding-left: 0;
  list-style: none;
  text-align: center;
  margin-top: -.5rem
}

.hero-search__suggested li {
  margin: 0 1rem;
  display: inline-block
}

.hero-search .nsw-form-search__input {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.nsw-page-nav {
  padding: 1rem;
  border-left: 4px solid #d7153a
}

.nsw-page-nav__title {
  margin: 0;
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.33
}

@media (min-width: 62em) {
  .nsw-page-nav__title {
    font-size: 1.25rem;
    line-height: 1.33
  }
}

.nsw-page-nav__list {
  margin: 0;
  padding: 0;
  list-style: none
}

.nsw-page-nav__list-item {
  margin-top: 1rem;
  display: block
}

.nsw-page-nav__link {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  color: #002664;
  display: inline-block
}

.nsw-page-nav__link:hover {
  background-color: rgba(0, 133, 179, .2);
  outline: 2px solid rgba(0, 133, 179, .2)
}

.nsw-page-nav__link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0
}

.nsw-page-nav__link:active {
  background-color: #0085b3;
  color: #fff
}

.nsw-link-list {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  margin-top: 1rem
}

.nsw-link-list__list {
  margin: 0;
  padding: 0;
  list-style-type: none
}

.nsw-link-list__item {
  border-top: 1px solid #e4e4e6
}

.nsw-link-list__item:last-child {
  border-bottom: 1px solid #e4e4e6
}

.nsw-link-list__link {
  padding: 1rem;
  color: #002664;
  display: flex;
  justify-content: space-between;
  align-items: center
}

.nsw-link-list__link:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-link-list__link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-link-list__icon {
  margin-left: 1rem;
  width: 1rem;
  height: 1rem;
  flex: none
}

.navigation-open {
  overflow: hidden
}

.nsw-navigation {
  z-index: 200;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  transition: all .25s ease-in-out;
  transform: translateX(-100%);
  visibility: hidden;
  outline: none;
  height: 100%;
  overflow-y: auto
}

.nsw-navigation.is-open, .nsw-navigation.is-opening {
  transform: translateX(0);
  visibility: visible
}

.nsw-navigation.is-closing {
  transform: translateX(-100%)
}

@media (min-width: 62em) {
  .nsw-navigation {
    position: relative;
    transform: none;
    background-color: #f4f4f7;
    top: auto;
    left: auto;
    transition: none;
    visibility: visible;
    border-top: 1px solid #a0a5ae;
    overflow-y: visible;
    height: auto
  }
}

.nsw-navigation__close {
  border-radius: .25rem;
  width: 2.5rem;
  height: 2.5rem;
  background: none;
  border: 0;
  padding: 0;
  position: relative;
  right: -.625rem;
  top: 0;
  text-align: center
}

.nsw-navigation__close:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-navigation__close:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-navigation__close .icon {
  height: 1.25rem;
  width: 1.25rem
}

@media (min-width: 62em) {
  .nsw-navigation__close {
    display: none
  }
}

.nsw-navigation__link-text {
  flex: 1 1 auto
}

.nsw-navigation__subbtn {
  width: 3.25rem;
  height: 3.25rem;
  background: none;
  border: 0;
  padding: 0;
  text-align: center;
  border-left: 1px solid #e4e4e6
}

.nsw-navigation__subbtn:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-navigation__subbtn:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-navigation__subbtn .nsw-icon {
  width: 1rem;
  height: 1rem
}

@media (min-width: 62em) {
  .nsw-navigation__subbtn {
    display: none
  }
}

.nsw-navigation__list, .nsw-subnavigation__list {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 62em) {
  .nsw-navigation__list, .nsw-subnavigation__list {
    max-width: 75rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    position: relative
  }
}

@media (min-width: 62em) {
  .nsw-subnavigation__list {
    margin-top: 2rem;
    display: flex
  }
}

.nsw-navigation__list-item, .nsw-subnavigation__list-item, .nsw-subnavigation__title {
  border-bottom: 1px solid #e4e4e6;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: nowrap
}

@media (min-width: 62em) {
  .nsw-navigation__list-item, .nsw-subnavigation__list-item, .nsw-subnavigation__title {
    border-bottom: 0
  }
}

.nsw-navigation__list-item, .nsw-subnavigation__list-item {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400
}

@media (min-width: 62em) {
  .nsw-navigation__list-item, .nsw-subnavigation__list-item {
    /*font-family: Montserrat, Arial, sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 600;
    border-bottom: 0
  }
}

@media (min-width: 62em) {
  .nsw-subnavigation__list-item {
    width: 32%;
    border-top: 1px solid #e4e4e6
  }

  .nsw-subnavigation__list-item:nth-child(3n+2) {
    margin-left: 2%;
    margin-right: 2%
  }

  .nsw-subnavigation__list-item:nth-last-child(-n+3) {
    border-bottom: 1px solid #e4e4e6
  }
}

.nsw-navigation__link, .nsw-subnavigation__link, .nsw-subnavigation__title-link {
  flex: 1 1 auto;
  padding: 16px;
  color: #333;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: nowrap
}

@media (min-width: 62em) {
  .nsw-navigation__link, .nsw-subnavigation__link, .nsw-subnavigation__title-link {
    z-index: 200;
    position: relative
  }
}

.nsw-navigation__link:hover, .nsw-subnavigation__link:hover, .nsw-subnavigation__title-link:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-navigation__link:focus, .nsw-subnavigation__link:focus, .nsw-subnavigation__title-link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0
}

.nsw-navigation__link.is-open, .nsw-subnavigation__link.is-open, .nsw-subnavigation__title-link.is-open {
  background-color: #fff
}

@media (min-width: 62em) {
  .nsw-subnavigation__link {
    color: #002664
  }
}

.nsw-navigation__link-icon {
  margin-top: .625rem;
  transform: translateY(-50%);
  height: 1rem;
  width: 1rem
}

@media (min-width: 62em) {
  .nsw-navigation__link-icon {
    display: block;
    height: .75rem;
    width: .75rem;
    margin-left: .625rem;
    transform: translateY(-50%) rotate(90deg)
  }
}

.nsw-navigation__link.is-open .nsw-navigation__link-icon {
  transform: translateY(-50%) rotate(270deg)
}

.nsw-subnavigation {
  z-index: 100;
  position: absolute;
  top: 0;
  background-color: #fff;
  width: 100%;
  transition: all .25s ease-in-out;
  transform: translateX(-100%);
  visibility: hidden;
  left: 0;
  height: 100%;
  overflow-y: auto
}

.nsw-subnavigation.is-open {
  transform: translateX(0);
  visibility: visible
}

@media (min-width: 62em) {
  .nsw-subnavigation {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding: 3rem 2.5rem;
    top: 100%;
    background: #fff;
    box-shadow: 0 4px 9px -5px rgba(0, 0, 0, .4);
    transition: none;
    transform: none;
    height: auto;
    overflow: auto
  }

  .nsw-subnavigation.is-open {
    left: 50%;
    transform: translateX(-50%)
  }
}

@media (min-width: 62em) {
  .nsw-subnavigation {
    padding-top: 3rem;
    padding-bottom: 3rem
  }
}

.nsw-navigation__header, .nsw-subnavigation__header {
  max-width: 75rem;
  padding: .5rem 1rem;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 2px solid #002664;
  display: flex;
  align-items: stretch;
  justify-content: space-between
}

.nsw-navigation__header h2, .nsw-subnavigation__header h2 {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.33;
  flex: 1 1 auto;
  -ms-grid-row-align: center;
  align-self: center
}

@media (min-width: 62em) {
  .nsw-navigation__header h2, .nsw-subnavigation__header h2 {
    font-size: 1.25rem;
    line-height: 1.33
  }
}

@media (min-width: 62em) {
  .nsw-navigation__header, .nsw-subnavigation__header {
    display: none
  }
}

.nsw-subnavigation__back-btn {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.33;
  padding: 0 .5rem;
  border-radius: .25rem;
  background: none;
  border: 0;
  display: inline-flex;
  text-decoration: none;
  align-items: center;
  position: relative;
  left: -8px
}

@media (min-width: 62em) {
  .nsw-subnavigation__back-btn {
    font-size: 1.25rem;
    line-height: 1.33
  }
}

.nsw-subnavigation__back-btn:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-subnavigation__back-btn:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-subnavigation__back-btn .nsw-icon {
  width: 1rem;
  height: 1rem;
  margin-right: .375rem;
  -ms-grid-row-align: center;
  align-self: center
}

@media (min-width: 62em) {
  .nsw-subnavigation__back-btn {
    display: none
  }
}

.nsw-subnavigation__title {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.33;
  margin: 0
}

@media (min-width: 62em) {
  .nsw-subnavigation__title {
    font-size: 1.25rem;
    line-height: 1.33;
    font-size: 1.375rem;
    line-height: 1.25;
    display: inline-block
  }
}

@media (min-width: 62em) and (min-width: 62em) {
  .nsw-subnavigation__title {
    font-size: 1.5rem;
    line-height: 1.25
  }
}

.nsw-subnavigation__title .nsw-card__icon {
  margin: 0 0 0 1rem;
  position: relative;
  top: .1875rem;
  display: none
}

@media (min-width: 62em) {
  .nsw-subnavigation__title .nsw-card__icon {
    display: inline
  }
}

@media (min-width: 62em) {
  .nsw-subnavigation__title-link {
    padding: 0;
    color: #002664
  }
}

.nsw-subnavigation__description {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  margin-top: 1rem;
  font-size: .875rem;
  line-height: 1.35;
  display: none
}

@media (min-width: 62em) {
  .nsw-subnavigation__description {
    font-size: .875rem;
    line-height: 1.35;
    display: block
  }
}

@media (min-width: 62em) {
  .nsw-subnavigation .nsw-navigation__link-icon, .nsw-subnavigation .nsw-subnavigation {
    display: none
  }
}

.nsw-notification {
  padding: 1rem;
  background: transparent;
  position: relative;
  border-left: 6px solid transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap
}

@media (min-width: 48em) {
  .nsw-notification {
    padding: 1.5rem 1.5rem 1.5rem 1rem
  }
}

.nsw-notification__icon {
  margin-right: 1rem;
  flex: 0 0 auto;
  width: 1.5rem;
  height: 1.5rem
}

@media (min-width: 48em) {
  .nsw-notification__icon {
    width: 2rem;
    height: 2rem
  }
}

.nsw-notification__content {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400
}

.nsw-notification__content p {
  margin: .75rem 0 0
}

.nsw-notification__content .nsw-text-link {
  margin-top: .75rem
}

.nsw-notification__title {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  margin: 0
}

.nsw-notification--info {
  background-color: #eaedf4;
  border-color: #2e5299
}

.nsw-notification--info .nsw-notification__icon {
  fill: #2e5299
}

.nsw-notification--success {
  background-color: #e5f6e6;
  border-color: #00a908
}

.nsw-notification--success .nsw-notification__icon {
  fill: #00a908
}

.nsw-notification--warning {
  background-color: #fbeee5;
  border-color: #dc5800
}

.nsw-notification--warning .nsw-notification__icon {
  fill: #dc5800
}

.nsw-notification--error {
  background-color: #f7e7eb;
  border-color: #b81237
}

.nsw-notification--error .nsw-notification__icon {
  fill: #b81237
}

.nsw-pagination {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  margin: .75rem 0 0;
  padding-left: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none
}

.nsw-pagination__item {
  margin: 0 0 0 .5rem
}

.nsw-pagination__item--next-page {
  margin: 0 0 0 1rem
}

.nsw-pagination__item--prev-page {
  margin: 0 1rem 0 0
}

.nsw-pagination__item--prev-page + .nsw-pagination__item {
  margin-left: 0
}

.nsw-pagination__link {
  position: relative;
  display: inline-flex;
  text-decoration: none;
  vertical-align: middle;
  padding: .25rem .5rem;
  color: #333;
  text-align: center;
  min-width: 1.5rem
}

.nsw-pagination__link:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-pagination__link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0
}

.nsw-pagination__link.is-current:after {
  position: absolute;
  content: "";
  bottom: 0;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background-color: #002664
}

.nsw-pagination__text {
  vertical-align: middle
}

.nsw-sidenav__header {
  border-bottom: 2px solid #002664
}

.nsw-sidenav__heading {
  margin: 0;
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5
}

@media (min-width: 62em) {
  .nsw-sidenav__heading {
    font-size: 1rem;
    line-height: 1.5
  }
}

.nsw-sidenav__list {
  margin: 0;
  padding-left: 0;
  list-style: none
}

.nsw-sidenav__list-item {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  border-top: 1px solid #e4e4e6
}

.nsw-sidenav__heading-link {
  padding: .5rem .5rem .5rem 0;
  display: block;
  color: #333;
  text-decoration: none;
  background-color: #fff
}

.nsw-sidenav__heading-link:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-sidenav__heading-link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0
}

.nsw-sidenav__link {
  padding: 1rem;
  display: block;
  color: #333;
  text-decoration: none;
  background-color: #fff
}

.nsw-sidenav__link:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-sidenav__link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0
}

.nsw-sidenav__link.is-current {
  background-color: #f4f4f7
}

.has-active-children > .nsw-sidenav__link {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600
}

.has-active-children > .nsw-sidenav__link:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-sidenav__list--level-2 .nsw-sidenav__link {
  padding-left: 1.5rem
}

.nsw-sidenav__list--level-3 .nsw-sidenav__link {
  padding-left: 2rem
}

.nsw-sidenav__list--level-4 .nsw-sidenav__link {
  padding-left: 3rem
}

.nsw-sidenav .nsw-sidenav__list--level-1 > .has-active-children {
  border-left: 2px solid #d7153a
}

.nsw-global-alert, .nsw-sitewide-message {
  padding: 1.5rem 0;
  position: relative;
  background-color: #2e5299;
  color: #fff
}

.nsw-global-alert--light, .nsw-sitewide-message--light {
  background-color: #eaedf4;
  color: #333
}

.nsw-global-alert--critical, .nsw-sitewide-message--critical {
  background-color: #b81237;
  color: #fff
}

.nsw-global-alert__close, .nsw-sitewide-message__close {
  position: absolute;
  top: -.75rem;
  right: .5rem;
  background: none;
  border: 0;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0
}

.nsw-global-alert__close:hover, .nsw-sitewide-message__close:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-global-alert__close:focus, .nsw-sitewide-message__close:focus {
  outline: 3px solid #fff;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-global-alert__close .nsw-icon, .nsw-sitewide-message__close .nsw-icon {
  fill: #fff;
  width: 1.5rem;
  height: 1.5rem
}

.nsw-global-alert__wrapper, .nsw-sitewide-message__wrapper {
  max-width: 75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  position: relative
}

.nsw-global-alert__title, .nsw-sitewide-message__title {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.33;
  margin-top: 0
}

@media (min-width: 62em) {
  .nsw-global-alert__title, .nsw-sitewide-message__title {
    font-size: 1.25rem;
    line-height: 1.33
  }
}

.nsw-global-alert__content, .nsw-sitewide-message__content {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  margin-right: 3rem
}

.nsw-global-alert__content p, .nsw-sitewide-message__content p {
  margin: 0
}

.nsw-global-alert__content * + p, .nsw-sitewide-message__content * + p {
  margin-top: 1rem
}

.nsw-global-alert .nsw-button, .nsw-sitewide-message .nsw-button {
  color: #333;
  background-color: #fff;
  border-color: #fff;
  display: block;
  margin-right: 0
}

.nsw-global-alert .nsw-button:hover, .nsw-sitewide-message .nsw-button:hover {
  color: #333;
  background-color: #e4e4e6;
  border-color: #e4e4e6
}

.nsw-global-alert .nsw-button:focus, .nsw-sitewide-message .nsw-button:focus {
  outline: 3px solid #fff;
  outline-offset: 0;
  outline-offset: 3px
}

@media (min-width: 48em) {
  .nsw-global-alert .nsw-button, .nsw-sitewide-message .nsw-button {
    display: inline-block
  }
}

.nsw-global-alert--dark .nsw-button, .nsw-sitewide-message--dark .nsw-button {
  color: #333;
  background-color: #fff;
  border-color: #fff
}

.nsw-global-alert--dark .nsw-button:hover, .nsw-sitewide-message--dark .nsw-button:hover {
  color: #333;
  background-color: #e4e4e6;
  border-color: #e4e4e6
}

.nsw-global-alert--dark .nsw-sitewide-message__close .nsw-icon, .nsw-sitewide-message--dark .nsw-sitewide-message__close .nsw-icon {
  fill: #fff
}

.nsw-global-alert--alert .nsw-button, .nsw-global-alert--light .nsw-button, .nsw-sitewide-message--alert .nsw-button, .nsw-sitewide-message--light .nsw-button {
  color: #fff;
  background-color: #002664;
  border-color: #002664
}

.nsw-global-alert--alert .nsw-button:hover, .nsw-global-alert--light .nsw-button:hover, .nsw-sitewide-message--alert .nsw-button:hover, .nsw-sitewide-message--light .nsw-button:hover {
  color: #fff;
  background-color: #003182;
  border-color: #003182
}

.nsw-global-alert--alert .nsw-button:focus, .nsw-global-alert--alert .nsw-sitewide-message__close:focus, .nsw-global-alert--light .nsw-button:focus, .nsw-global-alert--light .nsw-sitewide-message__close:focus, .nsw-sitewide-message--alert .nsw-button:focus, .nsw-sitewide-message--alert .nsw-sitewide-message__close:focus, .nsw-sitewide-message--light .nsw-button:focus, .nsw-sitewide-message--light .nsw-sitewide-message__close:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-global-alert--alert .nsw-sitewide-message__close .nsw-icon, .nsw-global-alert--light .nsw-sitewide-message__close .nsw-icon, .nsw-sitewide-message--alert .nsw-sitewide-message__close .nsw-icon, .nsw-sitewide-message--light .nsw-sitewide-message__close .nsw-icon {
  fill: #002664
}

.nsw-socials {
  margin: .75rem 0 0;
  list-style: none;
  padding: 0;
  display: flex
}

.nsw-socials__item {
  margin-right: 1rem
}

.nsw-socials__link {
  text-decoration: none;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem
}

.nsw-socials__link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-socials__icon {
  width: 1.5rem;
  height: 1.5rem
}

.nsw-tabs {
  margin-top: 1rem
}

.nsw-tabs__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative
}

.nsw-tabs__link {
  padding: .75rem 1rem;
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  z-index: 200;
  display: block;
  position: relative;
  text-decoration: none;
  color: #002664
}

.nsw-tabs__link:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-tabs__link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.is-selected .nsw-tabs__link {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  color: #333
}

.is-selected .nsw-tabs__link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  border-bottom: 4px solid #d7153a
}

.nsw-tabs__content {
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #e4e4e6;
  border-top: 2px solid #e4e4e6;
  margin-top: -2px
}

.nsw-tabs__content:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-tag:not(.nsw-tag--checkbox), .nsw-tag label {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: .875rem;
  line-height: 1.35;
  border-radius: .8125rem;
  border: 1px solid #2e5299;
  padding: 2px 1rem;
  color: #2e5299;
  text-decoration: none;
  display: inline-block;
  background-color: #fff
}

@media (min-width: 62em) {
  .nsw-tag:not(.nsw-tag--checkbox), .nsw-tag label {
    font-size: .875rem;
    line-height: 1.35
  }
}

a.nsw-tag:hover, button.nsw-tag:hover {
  background-color: rgba(0, 133, 179, .2)
}

a.nsw-tag:focus, button.nsw-tag:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.button.nsw-tag .is-selected {
  background-color: #2e5299;
  color: #fff
}

.nsw-tag--checkbox {
  display: inline-block
}

.nsw-tag--checkbox input {
  position: absolute;
  opacity: 0
}

.nsw-tag--checkbox input:hover + label {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-tag--checkbox input:focus + label {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-tag--checkbox input:checked + label {
  background-color: #2e5299;
  color: #fff
}

.nsw-skipto {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400
}

.nsw-skipto, .nsw-skipto__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%
}

.nsw-skipto__link {
  padding: .5rem 1rem;
  z-index: 200;
  background-color: #002664;
  color: #fff;
  transform: translateY(-101%);
  display: block;
  text-align: center;
  text-decoration: none
}

.nsw-skipto__link:active, .nsw-skipto__link:focus {
  transform: translateY(0);
  outline: none
}

.nsw-skipto__link:active span, .nsw-skipto__link:focus span {
  outline: 3px solid #fff;
  outline-offset: 0;
  outline-offset: 3px
}

@media (min-width: 62em) {
  .nsw-skipto__link {
    text-align: left
  }
}

.nsw-infobar, .nsw-masthead {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  padding: .75rem 0;
  background-color: #002664;
  color: #fff
}

.nsw-infobar p, .nsw-masthead p {
  margin: 0;
  font-size: .75rem
}

.nsw-header {
  background-color: #fff;
  position: relative;
  border-bottom: 1px solid #e4e4e6
}

@media (min-width: 62em) {
  .nsw-header {
    border-bottom: 0
  }
}

.nsw-header__container {
  max-width: 75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  position: relative
}

.nsw-header__wrapper {
  padding: .75rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  height: 4.5rem
}

@media (min-width: 62em) {
  .nsw-header__wrapper {
    justify-content: unset;
    height: 5.875rem
  }
}

.nsw-header__center {
  order: 2
}

@media (min-width: 62em) {
  .nsw-header__center {
    order: 0
  }
}

.nsw-header__left {
  order: 1;
  margin-left: -.375rem
}

.nsw-header__right {
  order: 3;
  margin-right: -.25rem
}

@media (min-width: 62em) {
  .nsw-header__right {
    margin-left: auto;
    margin-right: 0
  }
}

.nsw-header__logo-link {
  display: block
}

.nsw-header__logo-link:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-header__logo {
  width: 8.8125rem;
  height: 2.875rem
}

@media (min-width: 62em) {
  .nsw-header__logo {
    width: 10.5rem;
    height: 3.4375rem
  }
}

.nsw-header-btn {
  border-radius: .25rem;
  color: #002664;
  width: 3rem;
  height: 3rem;
  background: none;
  border: 0;
  padding: 0;
  text-align: center;
  position: relative
}

.nsw-header-btn:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-header-btn:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

.nsw-header-btn__icon {
  height: 1rem;
  width: 1rem;
  position: relative;
  top: 1px
}

.nsw-header-btn__sub {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: .75rem
}

@media (min-width: 62em) {
  .nsw-header-btn--menu {
    display: none
  }
}

@media (min-width: 62em) {
  .nsw-header-btn--search .nsw-header-btn__sub {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important
  }

  .nsw-header-btn--search .nsw-header-btn__icon {
    width: 1.5rem;
    height: 1.5rem
  }
}

.nsw-search__area {
  width: 100vw;
  position: absolute;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: #f4f4f7;
  top: 0
}

@media (min-width: 62em) {
  .nsw-search__area {
    width: auto;
    position: static;
    margin-left: auto;
    margin-right: auto;
    left: auto;
    right: auto;
    position: absolute;
    width: 24.625rem;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%)
  }
}

.nsw-search__input {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.33;
  background: none;
  padding: .5rem 4.5rem .5rem 1rem;
  color: #333;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  height: 4.5rem
}

@media (min-width: 62em) {
  .nsw-search__input {
    font-size: 1.25rem;
    line-height: 1.33
  }
}

.nsw-search__input:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0
}

@media (min-width: 62em) {
  .nsw-search__input {
    border-radius: .25rem;
    height: 3.125rem;
    padding: 0 1rem
  }
}

.nsw-search__search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: .75rem;
  width: 1.5rem;
  height: 1.5rem;
  display: none
}

@media (min-width: 62em) {
  .nsw-search__search-icon {
    display: inline-block
  }
}

.nsw-search__close-btn {
  width: 3rem;
  height: 3rem;
  background: none;
  border: 0;
  padding: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem
}

.nsw-search__close-btn:hover {
  background-color: rgba(0, 133, 179, .2)
}

.nsw-search__close-btn:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0;
  outline-offset: 3px
}

@media (min-width: 62em) {
  .nsw-search__close-btn {
    display: none
  }
}

.nsw-search__close-icon {
  width: 1.5rem;
  height: 1.5rem
}

.nsw-footer {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  margin-top: 1rem;
  border-top: 1px solid #a0a5ae
}

.nsw-footer hr {
  margin: 1rem 0;
  height: 1px;
  border: 0;
  color: #a0a5ae;
  background-color: #a0a5ae
}

.nsw-footer__upper {
  padding-top: 1.5rem;
  background-color: #fff
}

.nsw-footer__lower {
  padding: 1rem 0 1.5rem;
  background-color: #333;
  color: #fff;
  font-size: .75rem
}

.nsw-footer__lower a {
  color: #fff
}

.nsw-footer__lower a:focus {
  outline: 3px solid #fff;
  outline-offset: 0;
  outline-offset: 3px
}

@media (min-width: 48em) {
  .nsw-footer__info {
    display: flex;
    flex-wrap: nowrap
  }
}

.nsw-footer__copyright {
  flex: 1 1 auto
}

.nsw-footer__built {
  flex: 0 0 12.5rem
}

.nsw-footer__built a:hover {
  text-decoration: none
}

@media (min-width: 48em) {
  .nsw-footer__built {
    text-align: right
  }
}

.nsw-footer-links {
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.nsw-footer-links__item {
  margin: 0 0 1rem;
  flex: 1 0 50%;
  max-width: 50%
}

@media (min-width: 48em) {
  .nsw-footer-links__item {
    margin-right: 1.5rem;
    flex: 0 0 auto;
    max-width: none
  }
}

.nsw-footer-links__link {
  text-decoration: underline
}

.nsw-footer-links__link:hover {
  text-decoration: none
}

.nsw-footer-links__link:focus {
  outline: 3px solid #fff;
  outline-offset: 0;
  outline-offset: 3px
}

.section-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 48em) {
  .section-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap
  }
}

.section-links a {
  color: #002664
}

.section-links a:hover {
  background-color: rgba(0, 133, 179, .2);
  outline: 2px solid rgba(0, 133, 179, .2)
}

.section-links a:focus {
  outline: 3px solid #0085b3;
  outline-offset: 0
}

.section-links a:active {
  background-color: #0085b3;
  color: #fff
}

.section-links__group {
  padding-bottom: 1.5rem;
  flex: 1 0 auto;
  min-width: 16rem;
  max-width: 100%
}

@media (min-width: 48em) {
  .section-links__group {
    flex-basis: 50%;
    min-width: auto
  }
}

@media (min-width: 62em) {
  .section-links__group {
    flex-basis: 25%
  }
}

.section-links__heading {
  margin: 0;
  /*font-family: Montserrat, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5
}

@media (min-width: 62em) {
  .section-links__heading {
    font-size: 1rem;
    line-height: 1.5
  }
}

.section-links__list {
  display:flex;
  padding: 0;
  font-size: .875rem;
  line-height: 1.35;
  list-style: none;
  margin: .25rem 0 0;
  justify-content: space-between;
}

@media (min-width: 62em) {
  .section-links__list {
    font-size: .875rem;
    line-height: 1.35
  }
}

/*# sourceMappingURL=main.css.map */

.select-all {
    width: 100%;
    margin-bottom: 8px;
    text-align: right;
    font-size: 0.875rem;
}

.select-all button {
    display: inline-block;
    width: auto;
}
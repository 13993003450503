#add-group-menu {
    margin-top: 16px;
}

#add-group-menu button:hover {
    background-color: #F4F4F7;
    border-radius: 4px;
}

#add-group-menu button:active {
    background-color: #4C4F55;
    color: white;
    border-radius: 4px;
}
/* @todo rename file to match classes */

.facet-layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    grid-auto-rows: auto;
}



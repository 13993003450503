.lite-accordian {
    position: relative;
    width: 100%;
    min-height: 48px;
    /* border: 1px solid lightgrey; */
}

.lite-accordian > * {
    /* background-color: lightskyblue; */
}

.lite-accordian--title {
    font-weight: 600;
    display: inline-block;
    line-height: 48px;
    vertical-align: top;
    font-size: 1.25em;
}
.lite-accordian--button {
    position: absolute;
    display: inline-block;
    right: 0;
    width: 48px;
    height: 48px;
    /* background-color: lightseagreen; */
    cursor: pointer;

    line-height: 48px;
    text-align: center;
    
    font-family: 'health-stats-icons' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    transform: rotate(180deg);
}

.lite-accordian--button:before {
    content: "\e901";
    font-size: 32px;
}

.lite-accordian--body {
    /* display: none; */
}
@import "../toolbar/toolbar.css";

.chart-viewer {
  display: flex;
  flex-direction: column;
}

.chart-viewer {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  /* width: 1375px; */
  height: 80vh;
  overflow: hidden;
}

.chart-viewer--body {
  flex-grow: 1;
  position: relative;
  display: flex;
  height: calc(100% - 240px);
  /* 184px */
}

.chart-viewer--filter {
  min-width: 360px;
  position: absolute;
  z-index: 5;
  width: 360px;

  height: 100%;
  padding: 16px;

  background-color: white;

  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);

  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(var(--height) + 72px);

  max-width: 360px;
  transition: ease-in-out 600ms max-width;
}

.chart-viewer--filter.closed {
  display: none;
  max-width: 0px;
  overflow-x: hidden;
  transition: ease-in-out 400ms max-width;
}

@media (min-width: 900px) {
  .chart-viewer--filter {
    position: relative;
    flex-grow: 0;
    border-right: 1px solid #d8d8d8;
    box-shadow: none;
  }
}

.chart-viewer--display {
  flex-grow: 1; 
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* border: 1px solid blue; */
  /* background-color: purple;  */
}

.chart-viewer--canvas {
  width: 100%;
  padding: 16px;
  flex-grow: 1;
  /* border: 1px solid orange; */
  /* display: flex; */
  overflow-y: scroll;
}


#select-location-container, 
#select-measure-container,
#select-add-group-container {
    position: relative;
    width: 100%;
}

#select-measure-container {
    display: inline-block;
    width: auto;
}

.ui-selectmenu-button {
    padding: 12px;
    height: 48px;
    outline: none;
}

.ui-selectmenu-button:focus {
    color: initial;
}

.ui-selectmenu-button.ui-selectmenu-button-open,
.ui-selectmenu-button.ui-selectmenu-button-open:hover,
.ui-selectmenu-button.ui-selectmenu-button-open:focus,
.ui-selectmenu-button.ui-selectmenu-button-open:active {
    border-bottom-color: initial;
    border-radius: 4px 4px 0 0;
}

.ui-selectmenu-text,
.ui-selectmenu-icon {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.ui-selectmenu-icon {
    text-indent: 0;
}

.ui-selectmenu-button .ui-selectmenu-icon.ui-icon,
.ui-selectmenu-button:hover .ui-selectmenu-icon.ui-icon,
.ui-selectmenu-button:focus .ui-selectmenu-icon.ui-icon
.ui-selectmenu-button:active .ui-selectmenu-icon.ui-icon {
    background-image: none;
}

.ui-selectmenu-icon:before {
    content: "\e902";
}

.ui-selectmenu-open {
    width: 100%;
    overflow: hidden;
}

.ui-selectmenu-menu .ui-menu {
    width: 100%;
    border: none;
}

.ui-selectmenu-menu {
    border: 1px solid #4C4F55;
    border-top-color: lightgray;
    border-radius: 0 0 4px 4px;
}

.ui-menu-item-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui-menu {
    border-radius: 0;
}

.ui-menu-item,
.ui-menu-item-wrapper {
    height: 48px;
}

.ui-menu-item .ui-menu-item-wrapper {
    line-height: 48px;
    padding: 0 12px;
}

.ui-selectmenu-button:hover,
.ui-selectmenu-button:focus,
.ui-selectmenu-button:active {
    background-color: initial;
}
/* 
<div class="lite-button-menu">
    <div class="lite-button-menu--button">
        <div class="lite-button-menu--title">:title:</div>
        <div class="lite-button-menu--icon"></div>
    </div>
    <div class="lite-button-menu--body">
    </div>
</div>
*/

.lite-button-menu {

    position: relative;
    width: 100%;
    height: 48px;
    
    border: 2px solid #E6E6E6;
    border-radius: 4px;

    line-height: 48px;
    background-color: white;
}

.lite-button-menu>* {
    /* background-color: lightskyblue; */
}

.lite-button-menu--button {
    display: flex;
    border-radius: 4px;
    cursor: pointer;
}

.lite-button-menu_float .lite-button-menu--button {
    box-shadow: 0 4px 8px 0 rgba(74, 74, 74, 0.3);
}

.lite-button-menu--title {
    display: inline-block;
    width: calc(100% - 48px);
    line-height: 44px;
    vertical-align: top;
    padding: 0 0 0 16px;
    font-size: 1em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.lite-button-menu_float .lite-button-menu--title {
    font-size: 1.25em;
}

.lite-button-menu--icon {
    position: absolute;
    display: inline-block;
    top: -2px;
    right: 0;
    width: 48px;
    height: 48px;
    /* background-color: lightseagreen; */
    cursor: pointer;
    line-height: 48px;
    text-align: center;
    font-family: 'health-stats-icons' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
}

.lite-button-menu--icon:before {
    content: "\e901";
    font-size: 32px;
}

.lite-button-menu_float .lite-button-menu--icon:before {
    content: "\e907";
    font-size: 24px;
    color: #5291A0;
}

.lite-button-menu--body {
    display: none;
    position: absolute;
    margin-bottom: 16px;
    top: 56px;
    background: white;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(74, 74, 74, 0.3);
    padding: 8px;
    width: 100%;
    z-index: 1000;
}

.lite-button-menu--option {
    background: none;
    border: none;
    line-height: 48px;
    text-align: left;
    cursor: pointer;
}

@import './chart-viewer/chart-viewer.css';
@import './legend/legend.css';
@import './view-selector/view-selector.css';
@import './filter/filter.css';
@import './compare-menu/compare-menu.css';
@import './measure-menu/measure-menu.css';
@import './view/view.css';
@import './plotly.css';

.indicator-chart {
  font-variant-numeric: tabular-nums;
  margin-bottom: 1rem;
}

.indicator-chart--title {
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 600;
  font-size: 2.06rem;
  line-height: 1.45em;
  padding-top: 1.12em;
  margin-bottom: 0.333em;
}

.indicator-chart--subtitle {
  font-family: Montserrat, Arial, sans-serif;
  font-size: 1.75rem;
  line-height: 1.71em;
  margin-bottom: 0.357em;
  /* padding-top: 9px; */
}

@media (min-width: 992px) {
  .indicator-chart--title {
    font-size: 3rem;
    line-height: 3.75rem;
    margin-bottom: 3px;
    padding-top: 9px;
  }
  .indicator-chart--subtitle {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 32px;
  }
}
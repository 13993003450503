.ui-checkboxradio-radio-label .ui-checkboxradio-icon {
    border-radius: 24px;
    /* border: none; */
}

.ui-checkboxradio-radio-label .ui-checkboxradio-icon:before {
    font-family: 'health-stats-icons' !important;
    content: "\e906";
    position: relative;
    font-size: 30px;
    text-indent: 0;
    color: #4C4F55;
    left: -5px;
    top: 2px;
}

.ui-checkboxradio-radio-label.ui-checkboxradio-checked:before,
.ui-checkboxradio-radio-label.ui-checkboxradio-checked:before:hover {
    text-indent: 0;
}

.ui-checkboxradio-radio-label.ui-checkboxradio-checked:hover .ui-icon,
.ui-checkboxradio-radio-label.ui-checkboxradio-checked .ui-icon {
    /* border-width: 2px; */
    border: none;
    width: 20px;
    height: 20px;
    text-indent: 0;
}

/* @todo this style is not DRY with measures menu */
#compare-menu {
  width: 100%;
  min-width: 250px;
}

@media (min-width: 900px) {
  #compare-menu { 
      max-width: 400px;
  }
}

#compare-menu label.ui-sortable-handle:before {
  font-family: 'health-stats-icons' !important;
  content: "\e900";
  position: relative;
  left: -8px;
  top: 4px;
  font-size: 1.2em;
  font-weight: normal;
  color: #C3C2C2;
}
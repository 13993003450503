.view-selector {
    /* position: absolute; */
    /* right: 16px; */
    display: inline-flex;
    /* font-size: 24px; */
    ;
}

.view-selector--button {
    /* width: 48px;
    height: 72px;
    border: none;

    
    /* background-color: transparent; */

    color: #4C4F55;
    background-color: #FFFFFF;
    border: 2px solid #E6E6E6;
    border-right: none;
    
    /* border-radius: 4px; */
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.view-selector--button.disabled, .view-selector--button.disabled:active{
  color: #4C4F55;
  background-color: #FFFFFF;
  cursor: default;
}

.disabled {
  cursor: default;
  opacity: 0.2;
}

.view-selector--button:last-child {
    border-right: 2px solid #E6E6E6;
}
.view-selector--button:first-child {
    border-radius: 4px 0 0 4px;
}

.view-selector--button:last-child {
    border-radius: 0 4px 4px 0;
}

/* .view-selector--button {
    border-bottom: 4px solid transparent;
} */

/* .view-selector--button:first-child {
    border-bottom: 4px solid #5291A0;
    color: #5291A0;
} */

.view-selector--button.selected, .view-selector--button:active {
    color: white;
    background-color: #4C4F55;

    /* border-bottom: 4px solid #5291A0; */
    /* color: #5291A0; */
}
/* JQueryUI */

.ui-widget {
    font-family: inherit;
    background-color: white;
    border: 1px solid #4C4F55;
    border-radius: 4px;
    outline: none;
}

.ui-widget:hover {
    border-color: initial;
    background-color: white;
}

.ui-widget:active {
    color: initial;
}

.ui-button:focus {
    border-color: initial;
}

.ui-button.ui-state-active,
.ui-button.ui-state-active:hover
{
    border: initial;
    background-color: initial;    
    color: initial;
}

.ui-icon {
    font-family: 'health-stats-icons' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #5291A0;
}




:root {
  --unit: 8px;
  --xs-cols: 1fr;
  --sm-cols: 1fr 1fr;
  --md-cols: 1fr 1fr 1fr;
  --lg-cols: 1fr 1fr 1fr 1fr;
  --xl-cols: 1fr 1fr 1fr 1fr;
  --cols-1: auto;
  --cols-2: auto auto;
  --cols-3: auto auto auto;
  --cols-4: auto auto auto auto;
  --cols-5: auto auto auto auto;
  --xs-gutter: 8px;
  --sm-gutter: 8px;
  --md-gutter: 8px;
  --lg-gutter: 16px;
  --xl-gutter: 16px;
  --xs-margin: 16px;
  --sm-margin: 16px;
  --md-margin: 16px;
  --lg-margin: 32px;
  --xl-margin: 32px;
  /* --xs-break: 0; */
  --sm-break: 576;
  --md-break: 768;
  --lg-break: 992;
  --xl-break: 1200;
}

@media (min-width: 62em) {
  :root {
    --unit: 32px;
  }
}

/*
   * Layout
   */
/*
header, footer {
    background-color: #D8D8D8;
    height: 13rem;
    text-align: center;
} */

footer {
  /* margin-top: 72px; */
}

.main-section {
  margin: 3rem auto 5rem auto;
  margin: 0 auto 5rem auto;

  padding: 0 var(--xs-margin);
  /* max-width: 1200px; */
  max-width: 1400px;
}

.main-section._wide {
  max-width: 1400px;
}

section.breadcrumb {
  padding-top: 2rem;
  line-height: 1rem;
  margin-bottom: 0;
}
/* Variant for full width section, eg hero image */

.main-section.full {
  max-width: 100%;
}

@media (min-width: 576px) {
  .main-section {
    padding: 0 var(--sm-margin);
  }
}

@media (min-width: 768px) {
  .main-section {
    padding: 0 var(--md-margin);
  }
}

@media (min-width: 992px) {
  .main-section {
    padding: 0 var(--lg-margin);
  }
}

@media (min-width: 1200px) {
  .main-section {
    padding: 0 var(--xl-margin);
  }
}

.placeholder-text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/* A narrow container for improved readblity on large screens  */

.narrow-container {
  max-width: 1100px;
  margin: 0 auto;
}


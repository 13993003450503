
.toolbar-button {
    position: relative;
    color: #4C4F55;
    background-color: #FFFFFF;
    border: 2px solid #E6E6E6;
    border-radius: 4px;
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    cursor: pointer;   
}

.toolbar-button:active {
    color: white;
    background-color: #4C4F55; 
}

.toolbar-button.checked {
    color: white;
    background-color: #4C4F55;
}

.menu-button {
    position: relative;
}

.menu-button--bubble {
    /* visibility: hidden; */
}

.menu-button--list {
    position: absolute;

    /* width: 500px;
    height: 100px; */
    bottom: 60px;
    right: -23px;
    /* transform: translateX(-50%); */
    padding: 8px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(74, 74, 74, 0.3);
    
    z-index: 1000;
}

.menu-button--list label {
    display: block;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    cursor: pointer;
    line-height: 48px;
    white-space: nowrap;
}

.menu-button--list label:hover {
    background-color: #F4F4F7;
}

.menu-button--list label.info {
  font-style: italic;
  cursor: default;
}

.menu-button--list label.info:hover {
    background-color: white;
}

 .menu-button--triangle {
    position: absolute;
    width: 48px;
    height: 17px;
    bottom: 43px;
    z-index: 1001;
    /* background-color: green; */
    overflow: hidden;
 }
 .menu-button--triangle:after {
    position: absolute;
    z-index: 10002;
    /* left: 50%; */
    bottom: 4px;
    right: 10px;
    width: 16px;
    height: 16px;
    box-shadow: 0 4px 8px 0 rgba(74, 74, 74, 0.3);
    transform: rotate(45deg) translateX(-50%);
    background-color: white;
    content: " ";
}
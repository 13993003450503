@import 'font.css';
@import 'icon.css';
@import 'nsw.css';
@import 'typography.css';
@import 'layout.css';
@import 'form.css';
/* 
@import 'form.css';
@import 'components/nsw-breadcrumb/nsw-breadcrumb.css';


@import 'components/card/card.css';



 */
 @import 'components/chart/chart.css';
 @import 'components/labeled-text/labeled-text.css';
 @import 'components/text-button/text-button.css';
 @import 'components/lite-accordian/lite-accordian.css';
 @import 'components/lite-button-menu/lite-button-menu.css';
 @import 'components/nsw-accordian/nsw-accordian.css';
 @import 'components/source-container/source-container.css';
 @import 'components/indicator-chart/indicator-chart.css';
 @import 'components/indicator-chart/filter/add-group-menu.css';
 @import 'components/indicator-chart/filter/filter.css';
 @import 'components/indicator-chart/filter/group-options.css';
 @import 'components/indicator-chart/filter/location-selector.css';
 @import 'components/indicator-chart/filter/remove-group.css';
 @import 'components/indicator-chart/filter/select-all.css';
 @import 'components/link-list/link-list.css';
 @import 'components/jqueryui/jqueryui.css';
 @import 'components/jqueryui/select.css';
 @import 'components/jqueryui/checkbox.css';
 @import 'components/jqueryui/radio.css';
 @import '../../node_modules/ngx-toastr/toastr.css';

/* 
 * Global Styles and Overrides
 */


/* Universal Box Sizing with Inheritance Ref: https://css-tricks.com/box-sizing/ */

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}


/* Link Style */

a {
  /* text-decoration: none; */
  color: #002664;
}

a:visited {
  /* color: #551A8B; */
  /* color: initial; */
}
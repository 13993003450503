/* Widget */

.ui-checkboxradio-label.ui-widget {
    display: block;
    width: 100%;
    max-width: 100%;
    border: none;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
}

/* Label */


/* Icon */

.ui-checkboxradio-label .ui-checkboxradio-icon {
    box-shadow: none;
    vertical-align: bottom;
}

/* Input */

.ui-checkboxradio {
    visibility: hidden;
}

/* ---- To clean up */

/* .ui-checkboxradio-label.ui-widget {
    height: 48px;
    padding: 8px 12px;
    line-height: 24px;
} */

.ui-checkboxradio-icon.ui-icon {
    margin: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #4C4F55;
    
    border-radius: 2px;
    position: relative;
}

.ui-checkboxradio-icon.ui-icon:before {
    content: "\e909";
    font-size: 16px;
    line-height: 16px;
    color: white;
}
.ui-checkboxradio-icon-space {
    margin-right: 12px;
}

.ui-checkboxradio-icon.ui-icon,
.ui-button .ui-checkboxradio-icon.ui-icon {
    background-image: none;
}

.ui-button .ui-checkboxradio-icon.ui-state-checked {
    /* border: none; */
    background-color: #4C4F55;
    text-indent: 0;
}

.labeled-text {
    font-family: "Montserrat", Montserrat, Arial, sans-serif;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.labeled-text--text {
    font-family: "Montserrat", Montserrat, Arial, sans-serif;
}

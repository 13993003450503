.accordion {
    margin: 1rem 0 0
}

.accordion__title {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    line-height: 1.25;
    margin: .5rem 0 0;
    background-color: #f4f4f7;
    border-left: 4px solid #002664;
    padding: 1rem
}

@media (min-width: 62em) {
    .accordion__title {
        font-size:1rem
    }
}

.is-ready .accordion__title {
    padding: 0;
    border-left: 0;
    margin-top: 0
}

.accordion__button {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    line-height: 1.25;
    margin: .5rem 0 0;
    background-color: #f4f4f7;
    padding: 1rem;
    display: block;
    width: 100%;
    text-align: left;
    border: 0;
    border-left: 4px solid #002664;
    position: relative
}

@media (min-width: 62em) {
    .accordion__button {
        font-size:1rem
    }
}

.accordion__button:hover {
    background-color: rgba(0,171,230,.2)
}

.accordion__button:focus {
    outline: none;
    /* background-color: rgba(0,171,230,.2); */
    box-shadow: 0px -3px 0px 0px #00abe6;
    z-index: 1;
    position: relative
}

.accordion__icon {
    transform: translateY(-50%) rotate(90deg);
    height: 1rem;
    width: 1rem;
    position: absolute;
    top: 50%;
    right: 1rem
}

.is-open .accordion__icon {
    transform: translateY(-50%) rotate(270deg)
}

.accordion__content {
    padding: 1rem;
    border-left: 4px solid #002664
}
@import './toolbar-button.css';

.toolbar {
    background-color: #F4F4F7;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);
    /* height: 64px; */
    padding: 8px 12px;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 10;
}

.toolbar.right {
    justify-content: flex-end;
}

@media (min-width: 900px) {
    .toolbar {
        gap: 16px;
    }
}

.toolbar-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
    /* margin-right: 16px; */
}

.toolbar-group:last-child {
    /* margin: 0; */
}

.toolbar-group.grow {
    flex-grow: 1;
}

.toolbar-group.right {
    justify-content: flex-end;
}

@media (min-width: 600px) {
    .toolbar-group {
        flex-wrap: nowrap;
    }
}

@media (min-width: 900px) {
    .toolbar-group {
        width: auto;
    }
}

.toolbar-spacer {
    width: 16px;
    height: 8px;
    min-width: 16px;
    /* height: 100%; */
    /* flex-grow: 1; */
    /* border: 1px solid green; */
    display: inline-block;
}
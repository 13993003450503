@font-face {
  font-family: 'health-stats-icons';
  src:
    url('./fonts/health-stats-icons.ttf?k1nrkr') format('truetype'),
    url('./fonts/health-stats-icons.woff?k1nrkr') format('woff'),
    url('./fonts/health-stats-icons.svg?k1nrkr#health-stats-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'health-stats-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 32px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-grab_handle:before {
  content: "\e900";
}
.icon-chevron_down:before {
  content: "\e901";
}
.icon-chevron_up:before {
  content: "\e902";
}
.icon-double_chevron_left:before {
  content: "\e903";
}
.icon-double_chevron_right:before {
  content: "\e904";
}
.icon-radio_off:before {
  content: "\e905";
}
.icon-radio_on:before {
  content: "\e906";
}
.icon-plus:before {
  content: "\e907";
}
.icon-add_to_file:before {
  content: "\e908";
}
.icon-checkmark:before {
  content: "\e909";
}
.icon-citation:before {
  content: "\e90a";
}

.icon-confidence-intervals:before {
  content: "\e917";
}

.icon-download:before {
  content: "\e90b";
}
.icon-hyphenmark:before {
  content: "\e90c";
}
.icon-map:before {
  content: "\e90d";
}
.icon-pencil_and_axis:before {
  content: "\e90e";
}
.icon-snapshot:before {
  content: "\e90f";
}
.icon-barhorizontal:before {
  content: "\e90f";
}
.icon-table:before {
  content: "\e910";
}
.icon-trend:before {
  content: "\e911";
}
.icon-loading:before {
  content: "\e912";
}
.icon-close:before {
  content: "\e913";
}
.icon-open-new-tab:before {
  content: "\e914";
}
.icon-edit:before {
  content: "\e915";
}
.icon-remove:before {
  content: "\e916";
}
.icon-confidence_interval:before {
  content: "\e917";
}

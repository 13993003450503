
.location-selector label {

    display: block;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;

}

.location-selector label:hover {
    background-color: #F4F4F7;
    border-radius: 4px;
}

.location-selector label:active {
    background-color: #4C4F55;
    color: white;
    border-radius: 4px;
}

#location-container {
    margin-top: 16px;
}

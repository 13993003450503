.source-container {
  position: relative;
  padding: 0 16px;
}

.source-container figcaption {
  position: relative;
  margin-bottom: 4rem;
}

.source-container hr {
  position: relative;
}

.source-container p {
  font-size: 0.875rem;
  padding-top: 0px;
  margin-bottom: 0px;
}

.source-container .release-date {
    margin-top: 1rem;
}
